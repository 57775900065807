import { useQuery } from "@tanstack/react-query";
import app from "../../config/axiosConfig";

const _getInteractionHistory = async (candidateId?: string) => {
  if (!candidateId) return [];
  const response = await app.get(`api/interaction-history/${candidateId}`);
  if (response.status < 200 || response.status > 299) {
    throw new Error(
      "Error fetching interaction history: " + response.statusText
    );
  }
  return response.data;
};

const useInteractionHistory = (candidateId?: string, enabled = true) => {
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["history", candidateId],
    queryFn: () => _getInteractionHistory(candidateId),
    enabled: enabled && !!candidateId,
    staleTime: 1000 * 60 * 1,
    gcTime: 0,
  });

  return {
    history: data,
    error,
    isLoading: isLoading,
    refetch: refetch,
  };
};

export default useInteractionHistory;
