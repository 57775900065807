import { useQuery } from "@tanstack/react-query";
import qs from "qs";
import { apiV1 } from "../../config/axiosConfig";

const getTotalStatistics = async (jobIds: string[]) => {
  const response = await apiV1.get("candidates/statistics/total", {
    params: {
      jobIds: jobIds.join(","),
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
  if (response.status < 200 || response.status > 299) {
    throw new Error(
      "Error fetching statistics per job: " + response.statusText
    );
  }
  return response.data;
};

const useTotalStatistics = (jobIds: string[], enabled = false) => {
  const { data, error, isLoading } = useQuery({
    queryKey: ["candidates", "statistics", jobIds],
    queryFn: () => getTotalStatistics(jobIds),
    enabled: enabled,
  });

  return {
    totalStatistics: data,
    error,
    isDataLoading: isLoading,
  };
};

export default useTotalStatistics;
