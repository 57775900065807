import { useState } from "react";
import { useSelector } from "react-redux";
import { DotLoader } from "react-spinners";
//@ts-ignore
import CandidateCard from "../../components/CandidateCard/CandidateCard";
//@ts-ignore
import WhichJobModal from "../../components/modals/WhichJobModal";
//@ts-ignore
import SearchBar from "../../components/SearchBar";
import { LikeCandidateForJobsModal } from "../../components/modals/SelectJobForAction/LikeCandidateForJobsModal";
import { RejectCandidateForJobsModal } from "../../components/modals/SelectJobForAction/RejectCandidateForJobModal";
import { ECandidateJobAction } from "../../components/modals/SelectJobForAction/selectForJobActions.types";
import useCandidates from "../../hooks/candidates/useCandidates.js";
import useNavigateToConversation from "../../hooks/useNavigateToConversation.js";
import { ICandidate } from "../../types/candidate.types";
import { IJob } from "../../types/job.types";
import styles from "./RecruiterCandidatesPage.module.css";

const RecruiterCandidatesPage = () => {
  const userData = useSelector((state: any) => state.user.userData);
  const [filters, setFilters] = useState({});
  const { candidates, isCandidatesLoading } = useCandidates(filters);

  const [action, setAction] = useState<ECandidateJobAction | null>(null);
  const [candidate, setCandidate] = useState<ICandidate | null>(null);

  const { navigateToConversation } = useNavigateToConversation();

  const isFiltered = Object.values(filters).some((filter) => {
    if (Array.isArray(filter)) {
      return filter.length !== 0;
    } else if (typeof filter == "boolean" || typeof filter == "string") {
      return filter;
    }

    return false;
  });

  const handleLike = (candidate: ICandidate) => () => {
    setAction(ECandidateJobAction.LIKE);
    setCandidate(candidate);
  };

  const handleReject = (candidate: ICandidate) => {
    setAction(ECandidateJobAction.REJECT);
    setCandidate(candidate);
  };

  const handleMessage = (candidate: ICandidate) => () => {
    setAction(ECandidateJobAction.MESSAGE);
    setCandidate(candidate);
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <h1 className={styles.title}>Candidate Board</h1>
          <div className="flex flex-col justify-center gap-y-2 p-0 mt-4">
            <div className="flex flex-row items-center space-between">
              <p>
                <span className="font-bold">{candidates?.length}</span> match
                your criteria
              </p>
              <div className="flex">
                <SearchBar
                  isFiltered={isFiltered}
                  onSearch={setFilters}
                  onClear={setFilters}
                  userData={userData}
                />
                <span className="ml-3 pt-2">
                  {candidates?.length} {isFiltered && <>filtered </>} results
                </span>
              </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 lg:grid-cols-3 gap-5 text-black relative auto-rows-max grid-auto-column gap-x-5">
              {isCandidatesLoading ? (
                <DotLoader color="#868bff" />
              ) : (
                candidates?.map((candidate, i) => (
                  <CandidateCard
                    key={i}
                    candidate={candidate}
                    origin="candidateboard"
                    onLike={handleLike(candidate)}
                    onReject={() => handleReject(candidate)}
                    onMessage={handleMessage(candidate)}
                    showLikeButton
                    showMessageButton
                    showRejectButton={false}
                  />
                ))
              )}
            </div>
          </div>
        </div>
      </div>
      {candidate?.uid && (
        <>
          <RejectCandidateForJobsModal
            isOpen={action === ECandidateJobAction.REJECT}
            candidateId={candidate.uid}
            headerText="Select the job you want to reject the candidate for:"
            onClose={() => {
              setAction(null);
              setCandidate(null);
            }}
          />
          <LikeCandidateForJobsModal
            isOpen={action === ECandidateJobAction.LIKE}
            candidateId={candidate.uid}
            headerText="Select the job you want to like the candidate for:"
            onClose={() => {
              setAction(null);
              setCandidate(null);
            }}
          />
          <WhichJobModal
            action={ECandidateJobAction.MESSAGE}
            show={Boolean(candidate) && action === ECandidateJobAction.MESSAGE}
            candidateId={candidate.uid}
            onClose={() => {
              setAction(null);
              setCandidate(null);
            }}
            onJobSelect={(job: IJob) => {
              if (!candidate) return;
              navigateToConversation(candidate.uid, job.uid);
            }}
          >
            Select the job you want to message the candidate about:
          </WhichJobModal>
        </>
      )}
    </>
  );
};

export default RecruiterCandidatesPage;
