import classNames from "classnames";
import { collection, deleteDoc, doc, query } from "firebase/firestore";
import { useMemo, useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { BsIncognito } from "react-icons/bs";
import { FaEye } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { useSelector } from "react-redux";
import { DotLoader } from "react-spinners";
import { userSelector } from "../../app/userRedux/userSlice";
import Logo from "../../components/firms/Logo";
import { db } from "../../config/firebase";
import { IMAGES } from "../../constants/images";
import { messageDateDisplay } from "../../utils/dates";
import styles from "./Conversation.module.css";

const stripHtmlTags = (htmlString) => {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = htmlString;
  return tempElement.textContent || tempElement.innerText || "";
};

const removeTrailingNbsp = (message) => {
  while (message.endsWith("&nbsp;")) {
    message = message.slice(0, -6); // Remove the last 6 characters (length of "&nbsp;")
  }
  return message;
};

const getProfileImageSrc = (conversation) => {
  if (conversation.incognito) {
    return IMAGES.U_ICON;
  } else {
    return conversation.conversationPartner?.profileImg
      ? conversation.conversationPartner.profileImg
      : IMAGES.U_ICON;
  }
};

const Conversation = ({ conversation, onClick, activeConversationId }) => {
  const user = useSelector(userSelector);
  const conversationPartner = conversation.conversationPartner ?? {
    name: "unknown",
  };
  const [isDeletingConversation, setIsDeletingConversation] = useState(false);

  const [messages, loadingMessages] = useCollectionData(
    query(collection(db, "conversations", conversation.id, "messages"))
  );

  const handleDeleteEmptyConversation = async (e) => {
    try {
      e.stopPropagation();
      setIsDeletingConversation(true);
      await deleteDoc(doc(db, "conversations", conversation.id));
      setIsDeletingConversation(false);
    } catch (error) {
      console.error("Error deleting conversation: ", error);
      throw error;
    }
  };

  const unreadMessagesCount = messages
    ? messages.filter(
        (message) => !message.read && message.sentBy !== user?.uid
      ).length
    : 0;

  const messagesSorted = messages
    ? messages.sort((a, b) => {
        if (a.date && b.date) {
          return a.date.toDate() - b.date.toDate();
        }
        return 0; // If dates are not available or not in expected format
      })
    : [];

  const lastMessage =
    messagesSorted.length > 0
      ? messagesSorted[messagesSorted.length - 1]?.message
      : null;
  const lastMessageDate =
    messagesSorted.length > 0
      ? messagesSorted[messagesSorted.length - 1]?.date
      : null;

  const sanitisedLastMessage = useMemo(
    () => (lastMessage ? removeTrailingNbsp(stripHtmlTags(lastMessage)) : ""),
    [lastMessage]
  );

  const IMAGE_SRC = getProfileImageSrc(conversation);

  return (
    <div
      className={classNames(styles.container, {
        [styles.isSelected]: activeConversationId === conversation.id,
        [styles.isAnonymous]: conversation.incognito,
        [styles.hasUnreadMessages]: unreadMessagesCount !== 0,
      })}
      onClick={onClick}
    >
      <div className={styles.left}>
        {conversation.conversationPartner?.isEmployer ? (
          <Logo src={conversation.conversationPartner.firmLogo} size={40} />
        ) : (
          <img
            src={IMAGE_SRC}
            id={conversation.id}
            className={classNames(styles.logo, "object-cover")}
            alt=""
          />
        )}
      </div>
      <div className={`${styles.middle} `}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              flex: 1,
              overflow: "hidden",
            }}
          >
            <p className={styles.title}>
              {!conversationPartner?.isEmployer
                ? conversation.incognito
                  ? conversationPartner.name
                  : conversationPartner.surname
                    ? conversationPartner.name +
                      " " +
                      conversationPartner.surname
                    : conversationPartner.name
                : conversationPartner.companyName}
            </p>
            <p className={styles.title}>{conversation.aboutJob.jobTitle}</p>
            {lastMessage && (
              <p className={styles.lastMessage}>{sanitisedLastMessage}</p>
            )}
          </div>
          <div>
            {conversation.incognito ? (
              <BsIncognito className="text-[#D8E0EB]" />
            ) : (
              <FaEye className="text-[#b3ffa7]" />
            )}
          </div>
        </div>
      </div>
      <div className={styles.right}>
        {lastMessageDate && (
          <p className={styles.lastMessageDate}>
            {messageDateDisplay(lastMessageDate.toDate())}
          </p>
        )}
        {unreadMessagesCount !== 0 && (
          <div className={styles.unreadMessagesCount}>
            {unreadMessagesCount}
          </div>
        )}
        {messages?.length === 0 && (
          <button
            disabled={isDeletingConversation}
            onClick={handleDeleteEmptyConversation}
          >
            {isDeletingConversation ? (
              <DotLoader
                color={"#ffffff"}
                loading={isDeletingConversation}
                size={20}
              />
            ) : (
              <MdDelete size={18} className="" />
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default Conversation;
