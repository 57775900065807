import { useCallback } from "react";
import useLikeCandidate from "../../../hooks/candidates/useLikeCandidate";
import { useJobInteractionsForCandidate } from "../../../hooks/jobInteractions/useJobInteractionsForCandidate";
import { IJob } from "../../../types/job.types";
import WhichJobModal from "../WhichJobModal";
//@ts-ignore
import { sendNotification } from "../../../app/functions";
//@ts-ignore
import { NOTIFICATION_TYPES } from "../../Notifications/index";
import { ECandidateJobAction } from "./selectForJobActions.types";

interface IProps {
  headerText: string;
  candidateId: string;
  isOpen: boolean;
  onClose?: () => void;
}

export const LikeCandidateForJobsModal = ({
  headerText,
  candidateId,
  isOpen,
  onClose,
}: IProps) => {
  const { likeCandidate, undoLikeCandidate } = useLikeCandidate();

  const { jobInteractions } = useJobInteractionsForCandidate({
    candidateId: candidateId,
  });

  const onJobSelect = useCallback(
    (job: IJob) => {
      const jobInteraction = (jobInteractions || []).find(
        (interaction) => interaction.jobId === job.uid
      );
      if (jobInteraction && jobInteraction.employerReview === "liked") {
        undoLikeCandidate({
          candidateId: candidateId,
          jobId: job.uid,
        });
      } else {
        likeCandidate(
          { candidateId: candidateId, jobId: job.uid },
          {
            onSuccess: (newData) => {
              if (newData.isMatch) {
                sendNotification(
                  job.userid,
                  candidateId,
                  job.uid,
                  NOTIFICATION_TYPES.MATCH_JOB
                );
                sendNotification(
                  candidateId,
                  job.uid,
                  job.uid,
                  NOTIFICATION_TYPES.MATCH_CANDIDATE
                );
              } else {
                sendNotification(
                  candidateId,
                  job.uid,
                  job.uid,
                  NOTIFICATION_TYPES.LIKE_CANDIDATE
                );
              }
            },
          }
        );
      }
    },
    [jobInteractions, candidateId, likeCandidate, undoLikeCandidate]
  );

  return (
    <WhichJobModal
      action={ECandidateJobAction.LIKE}
      show={isOpen && Boolean(candidateId)}
      candidateId={candidateId}
      onClose={() => onClose?.()}
      onJobSelect={onJobSelect}
    >
      {headerText}
    </WhichJobModal>
  );
};
