import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiV1 } from "../../config/axiosConfig";
// @ts-ignore
import { sendNotification } from "../../app/functions";
// @ts-ignore
import { NOTIFICATION_TYPES } from "../../components/Notifications";

const _likeJob = async (jobId: string) => {
  const response = await apiV1.put(`jobs/${jobId}/like`);
  if (response.status < 200 || response.status > 299) {
    throw new Error("Error liking job: " + response.statusText);
  }
  return response.data;
};

interface IMutationProps {
  jobId: string;
  userId: string;
  jobUserId: string;
  queryObject?: any;
}

const useLikeJob = () => {
  const queryClient = useQueryClient();

  const { isPending: isUpdating, mutate: likeJob } = useMutation<
    any,
    Error,
    IMutationProps,
    { previousJobs: any[] }
  >({
    mutationFn: ({ jobId }) => _likeJob(jobId),
    onMutate: async ({ jobId, queryObject = {} }) => {
      let queryKey = ["jobs", queryObject];

      if (queryObject == null) {
        queryKey = ["jobs"];
      }

      await queryClient.cancelQueries({ queryKey });

      const previousJobs: any[] = queryClient.getQueryData(queryKey) ?? [];

      const job = previousJobs?.find((job: any) => job?.uid === jobId);

      if (job == null) return { previousJobs };

      const updatedJobs = previousJobs.filter((job: any) => job?.uid !== jobId);

      queryClient.setQueryData(["jobs", queryObject], updatedJobs);

      return { previousJobs };
    },
    onError: (error, { queryObject }, context) => {
      queryClient.setQueryData(["jobs", queryObject], context?.previousJobs);
    },
    onSuccess: async (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["job-interactions"],
      });
      if (data.isMatch) {
        await Promise.all([
          sendNotification(
            variables.userId,
            variables.jobId,
            variables.jobId,
            NOTIFICATION_TYPES.MATCH_CANDIDATE
          ),
          sendNotification(
            variables.jobUserId,
            variables.userId,
            variables.jobId,
            NOTIFICATION_TYPES.MATCH_JOB
          ),
        ]);
      }
      if (!data.isMatch && data.isLiked) {
        sendNotification(
          variables.jobUserId,
          variables.userId,
          variables.jobId,
          NOTIFICATION_TYPES.LIKE_JOB
        );
      }
    },
    onSettled: () => {
      if (queryClient.isMutating({ mutationKey: ["jobs"] }) === 1) {
        queryClient.invalidateQueries({ queryKey: ["jobs"] });
      }
      queryClient.invalidateQueries({
        queryKey: ["jobs", { type: "my-matches-stats" }],
      });
    },
  });

  return {
    isLoading: isUpdating,
    likeJob,
  };
};

export default useLikeJob;
