import classnames from "classnames";
import styles from "./MatchPercentageBadge.module.css";

interface IProps {
  inline?: boolean;
  percentage: number;
  style?: React.CSSProperties;
}

const MatchPercentageBadge = ({
  inline = false,
  percentage,
  style,
}: IProps) => {
  return (
    <div
      className={classnames(styles.container, { [styles.inline]: inline })}
      style={{ ...style }}
    >
      {percentage}% match
    </div>
  );
};

export default MatchPercentageBadge;
