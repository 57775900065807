import { useCallback, useState } from "react";
import { Button, Tabs } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import { useNavigate, useParams } from "react-router-dom";
import { DotLoader } from "react-spinners";
import { useQueryParam } from "use-query-params";
import RecruiterMatchModal from "../../components/RecruiterMatchModal";
import useCandidates from "../../hooks/candidates/useCandidates";
import useMyJob from "../../hooks/jobs/useMyJob";
import useStatisticsPerJob from "../../hooks/jobs/useStatisticsPerJob";
import JobPageContent from "./JobsTabs/JobPageContent";
import { EJobPageTabs } from "./JobsTabs/JobTabs.util";
import TabTitle from "./JobsTabs/TabTitle";

const DEFAULT_TAB: EJobPageTabs = EJobPageTabs.Applicants;

const JobPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [tab] = useQueryParam<EJobPageTabs>("tab");

  const [showMatchModal, setShowMatchModal] = useState(false);
  const [activeTab, setActiveTab] = useState(tab ?? DEFAULT_TAB);

  const { statisticsPerJob } = useStatisticsPerJob(id);
  const { myJob, isMyJobLoading } = useMyJob(id);

  const getQueryBasedOnTab = useCallback(
    (activeTab: EJobPageTabs, jobId?: string) => {
      if (!jobId) return { jobId, queryField: EJobPageTabs.Applicants };
      switch (activeTab) {
        case EJobPageTabs.Potential:
          return { jobId, queryField: EJobPageTabs.Potential };
        case EJobPageTabs.Applicants:
          return { jobId, queryField: EJobPageTabs.Applicants };
        case EJobPageTabs.LikedByMe:
          return { jobId, queryField: EJobPageTabs.LikedByMe };
        case EJobPageTabs.Matches:
          return { jobId, queryField: EJobPageTabs.Matches };
        case EJobPageTabs.LikesMyJob:
          return { jobId, queryField: EJobPageTabs.LikesMyJob };
        case EJobPageTabs.NotInterested:
          return { jobId, queryField: EJobPageTabs.NotInterested };
        default:
          return { jobId, queryField: EJobPageTabs.Applicants };
      }
    },
    []
  );

  const { candidates, isCandidatesLoading } = useCandidates(
    getQueryBasedOnTab(activeTab, id)
  );

  const handleCloseMatchModal = () => {
    setShowMatchModal(false);
  };

  const handleTabSelect = (key: string | null) => {
    if (key) setActiveTab(key as EJobPageTabs);
  };

  const handelJobOverview = () => {
    navigate(`/job-overview/${myJob.uid}`);
  };

  const orderedCandidates = (candidates || []).sort(
    (a, b) => b.matchedPercentage - a.matchedPercentage
  );

  if (isMyJobLoading) {
    return;
  }

  return (
    <>
      <div
        className="flex flex-column items-center h-screen text-white relative z-0"
        style={{
          height: "calc(100vh - 62px)", // Adjust the height based on your navbar height
        }}
      >
        <div className="flex flex-column items-center justify-center w-[90%] lg:w-[80%]">
          <div className="flex flex-row space-between w-full mt-20">
            <div>
              <p className="font-bold text-lg">{myJob?.jobTitle}</p>
              <p className="text-sm text-white opacity-60">
                Posted {new Date(myJob.createdAt).toLocaleDateString()}
              </p>
            </div>
            <div>
              <Button
                variant="outlined"
                style={{
                  color: "#FFC83A",
                  borderColor: "#FFC83A",
                }}
                onClick={handelJobOverview}
              >
                Preview job post
              </Button>
            </div>
          </div>
          {/* search bar */}
          {isCandidatesLoading && <DotLoader color="#868bff" />}
          <div className="mt-4 absolute top-[280px]  md:top-[163px] w-[90%] lg:w-[80%] pb-14">
            {/**
             * NOTE ON TABS: Don't try to extract this as a component (I tried an failed).
             * A 'Tab' in React-bootstrap should always be added directly below the "Tabs" component,
             * it won't render otherwise (unless we create a custom implementation using
             * Tab.Container, Tab.Content and Tab.Pane)
             * */}
            <Tabs
              defaultActiveKey={DEFAULT_TAB}
              variant=""
              id="noanim-tab-example"
              className="flex flex-col md:flex-row mb-3 border-none ml-[-14px]"
              onSelect={handleTabSelect}
            >
              <Tab
                eventKey={EJobPageTabs.Potential}
                active={activeTab === EJobPageTabs.Potential}
                title={
                  <TabTitle
                    amount={statisticsPerJob?.amountPotentialCandidates}
                    title="Potential Candidates"
                    isActive={activeTab === EJobPageTabs.Potential}
                  />
                }
              >
                <JobPageContent
                  tab={EJobPageTabs.Potential}
                  candidates={orderedCandidates}
                  job={myJob}
                />
              </Tab>
              <Tab
                eventKey={EJobPageTabs.LikedByMe}
                active={activeTab === EJobPageTabs.LikedByMe}
                title={
                  <TabTitle
                    amount={statisticsPerJob?.amountSavedCandidates}
                    title="Liked Candidates"
                    isActive={activeTab === EJobPageTabs.LikedByMe}
                  />
                }
              >
                <JobPageContent
                  tab={EJobPageTabs.LikedByMe}
                  candidates={orderedCandidates}
                  job={myJob}
                />
              </Tab>
              <Tab
                eventKey={EJobPageTabs.NotInterested}
                active={activeTab === EJobPageTabs.NotInterested}
                title={
                  <TabTitle
                    amount={statisticsPerJob?.amountNotInterested}
                    title="Not Interested"
                    isActive={activeTab === EJobPageTabs.NotInterested}
                  />
                }
              >
                <JobPageContent
                  tab={EJobPageTabs.NotInterested}
                  candidates={orderedCandidates}
                  job={myJob}
                />
              </Tab>
              <Tab
                eventKey={EJobPageTabs.LikesMyJob}
                active={activeTab === EJobPageTabs.LikesMyJob}
                title={
                  <TabTitle
                    amount={statisticsPerJob?.amountCandidateLikes}
                    title="Likes Job"
                    isActive={activeTab === EJobPageTabs.LikesMyJob}
                  />
                }
              >
                <JobPageContent
                  tab={EJobPageTabs.LikesMyJob}
                  candidates={orderedCandidates}
                  job={myJob}
                />
              </Tab>
              <Tab
                eventKey={EJobPageTabs.Matches}
                active={activeTab === EJobPageTabs.Matches}
                title={
                  <TabTitle
                    amount={statisticsPerJob?.amountMatchedCandidates}
                    title="Matches"
                    isActive={activeTab === EJobPageTabs.Matches}
                  />
                }
              >
                <JobPageContent
                  tab={EJobPageTabs.Matches}
                  candidates={orderedCandidates}
                  job={myJob}
                />
              </Tab>
              <Tab
                eventKey={EJobPageTabs.Applicants}
                active={activeTab === EJobPageTabs.Applicants}
                title={
                  <TabTitle
                    amount={statisticsPerJob?.amountApplicants}
                    title="Applicants"
                    isActive={activeTab === EJobPageTabs.Applicants}
                  />
                }
              >
                <JobPageContent
                  tab={EJobPageTabs.Applicants}
                  candidates={orderedCandidates}
                  job={myJob}
                />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
      <RecruiterMatchModal
        show={showMatchModal}
        handleClose={handleCloseMatchModal}
      />
    </>
  );
};

export default JobPage;
