import { useQuery } from "@tanstack/react-query";
import { apiV1 } from "../../config/axiosConfig";
import { IJob } from "../../types/job.types";

const _getJob = async (jobId: string): Promise<IJob> => {
  const response = await apiV1.get(`jobs/${jobId}`);
  if (response.status < 200 || response.status > 299) {
    throw new Error("Error fetching job: " + response.statusText);
  }
  return response.data;
};

const useJob = (jobId: string, enabled = true) => {
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["jobs", jobId],
    queryFn: () => _getJob(jobId),
    enabled: enabled,
  });

  return {
    job: data,
    error,
    isJobLoading: isLoading,
    refetch: refetch,
  };
};

export default useJob;
