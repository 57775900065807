import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiV1 } from "../../config/axiosConfig";

interface IMutationProps {
  jobId: string;
  candidateId: string;
}

const _rejectCandidateForJob = async ({
  jobId,
  candidateId,
}: IMutationProps) => {
  const response = await apiV1.put(
    `/my-jobs/${jobId}/candidate/${candidateId}/reject`
  );
  if (response.status < 200 || response.status > 299) {
    throw new Error("Error rejecting job: " + response.statusText);
  }
  return response.data;
};

const _undoRejectCandidateForJob = async ({
  jobId,
  candidateId,
}: IMutationProps) => {
  const response = await apiV1.put(
    `/my-jobs/${jobId}/candidate/${candidateId}/undo-reject`
  );
  if (response.status < 200 || response.status > 299) {
    throw new Error("Error undoing reject job: " + response.statusText);
  }
  return response.data;
};

const useRejectCandidate = () => {
  const queryClient = useQueryClient();

  const { isPending: isRejecting, mutate: rejectCandidate } = useMutation<
    any,
    Error,
    IMutationProps
  >({
    mutationFn: _rejectCandidateForJob,
    onSuccess: (message, { jobId, candidateId }) => {
      // If this every becomes a bottleneck we can do this invalidation more finegrained (ony specific tabs),
      // but for now this is fine
      queryClient.invalidateQueries({
        queryKey: ["candidates"],
      });
      queryClient.invalidateQueries({
        queryKey: ["job-interactions", { jobId, candidateId }],
      });
    },
  });

  const { isPending: isUndoingReject, mutate: undoRejectCandidate } =
    useMutation({
      mutationFn: _undoRejectCandidateForJob,
      onSuccess: (message, { jobId, candidateId }) => {
        // If this every becomes a bottleneck we can do this invalidation more finegrained (ony specific tabs),
        // but for now this is fine
        queryClient.invalidateQueries({
          queryKey: ["candidates"],
        });

        queryClient.invalidateQueries({
          queryKey: ["job-interactions", { jobId, candidateId }],
        });
      },
    });

  return {
    isPending: isRejecting || isUndoingReject,
    rejectCandidate,
    undoRejectCandidate,
  };
};

export default useRejectCandidate;
