import { useMemo, useState } from "react";
import { Button, Stack } from "react-bootstrap";
import { BiMessage } from "react-icons/bi";
import { CiLocationOn } from "react-icons/ci";
import { MdOutlineMenu, MdRemoveRedEye } from "react-icons/md";
import { PiCaretCircleLeftFill, PiSuitcaseLight } from "react-icons/pi";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DotLoader } from "react-spinners";
//@ts-ignore
import harvey from "../../assets/example_images/harvey.jpg";
import { LikeButton } from "../../components/buttons";
//@ts-ignore
import ExperienceList from "../../components/ExperienceList/ExperienceList";
import HistoryModal from "../../components/modals/HistoryModal/HistoryModal";
import { LikeCandidateForJobsModal } from "../../components/modals/SelectJobForAction/LikeCandidateForJobsModal";
import { RejectCandidateForJobsModal } from "../../components/modals/SelectJobForAction/RejectCandidateForJobModal";
import { ECandidateJobAction } from "../../components/modals/SelectJobForAction/selectForJobActions.types";
import WhichJobModal from "../../components/modals/WhichJobModal";
//@ts-ignore
import { IMAGES } from "../../constants/images";
import useCandidate from "../../hooks/candidates/useCandidate";
import { useJobInteraction } from "../../hooks/jobInteractions/useJobInteraction";
import { useJobInteractionsForCandidate } from "../../hooks/jobInteractions/useJobInteractionsForCandidate";
import useInteractionHistory from "../../hooks/notifications/useInteractionHistory";
//@ts-ignore
import useCheckIfAnonymous from "../../hooks/useCheckIfAnonymous";
import useNavigateToConversation from "../../hooks/useNavigateToConversation";
import { ESkills } from "../../types/candidate.types";
import { IJob } from "../../types/job.types";
import { experienceTotal } from "../../utils/helpers";
import { JobInteractionList } from "./JobInteractionList/JobInteractionList";
import { JobInteractionListItem } from "./JobInteractionList/JobInteractionListItem";
import styles from "./RecruiterCandidatePagePreview.module.css";

const RecruiterCandidatePagePreview = () => {
  const { candidateId, jobUid } = useParams();
  const navigate = useNavigate();
  const { state: routingState } = useLocation();

  const userData = useSelector((state: any) => state.user.userData);
  const { candidate, isCandidateLoading } = useCandidate(
    candidateId ?? userData.uid,
    {
      jobId: jobUid,
    }
  );
  const { jobInteraction, isLoading } = useJobInteraction({
    jobId: jobUid,
    candidateId: candidateId,
  });
  const { jobInteractions } = useJobInteractionsForCandidate({
    candidateId: candidate?.uid,
  });

  const { navigateToConversation } = useNavigateToConversation();
  const { history } = useInteractionHistory(candidateId);

  const { incognito, loading } = useCheckIfAnonymous(
    userData.uid,
    candidateId ?? userData.uid,
    jobUid
  );

  const { isLiked, isRejected } = useMemo(() => {
    if (jobInteraction && !isLoading) {
      const isLiked = jobInteraction.employerReview === "liked";
      const isRejected = jobInteraction.employerReview === "rejected";
      const isMatched =
        jobInteraction.candidateReview === "liked" &&
        jobInteraction.employerReview === "liked";
      return { isLiked, isRejected, isMatched };
    }
    return { isLiked: false, isRejected: false, isMatched: false };
  }, [jobInteraction, isLoading]);

  const isLikedForAtLeastOneJob = useMemo(() => {
    if (!jobInteractions) return false;
    return jobInteractions.some(
      (interaction) => interaction.employerReview === "liked"
    );
  }, [jobInteractions]);

  const isRejectedForAtLeastOneJob = useMemo(() => {
    if (!jobInteractions) return false;
    return jobInteractions.some(
      (interaction) => interaction.employerReview === "rejected"
    );
  }, [jobInteractions]);

  const [action, setAction] = useState<ECandidateJobAction | null>(null);

  const [showHistoryModal, setShowHistoryModal] = useState(false);

  const flipDateString = (dateString: string) => {
    const parts = dateString?.split("-");
    const flippedDateString = parts?.reverse().join("-");
    return flippedDateString;
  };

  const handleGoBack = () => {
    const { conversationId, jobId, jobTab } = routingState || {};
    if (conversationId) {
      navigate("/chat-room", {
        state: { conversationId },
      });
    } else if (jobId) {
      navigate(`/my-job/${jobId}?tab=${jobTab}`);
    } else {
      //window.history.back();
      navigate(-1);
    }
  };

  if (isCandidateLoading || !candidate || loading) {
    return <DotLoader color="#868bff" />;
  }

  const isIncognito = incognito;
  return (
    <div className={styles.container}>
      <div
        className={
          (styles.contentsection,
          "flex flex-row items-center justify-start gap-2 w-[90%] lg:w-[80%] mt-5 gap-4")
        }
      >
        <div className="flex flex-col flex-row items-center">
          <Button
            variant="outlined"
            className="flex items-center border-none p-0"
            onClick={handleGoBack}
          >
            {userData.isEmployer ? (
              <span className="flex flex-row gap-2 items-center">
                <PiCaretCircleLeftFill
                  size={38}
                  className="text-[#46D6EA] duration-75 hover:scale-125"
                />
                Back
              </span>
            ) : (
              <span className="flex flex-row gap-2 items-center">
                <img src={IMAGES.EXIT} width={20} height={20} />
                Exit anonymous mode
              </span>
            )}
          </Button>
        </div>
      </div>
      <div
        className={
          (styles.contentsection,
          "flex flex-col md:flex-row items-start justify-center w-[90%] lg:w-[80%] mt-5 gap-4")
        }
      >
        <div className="flex flex-col gap-4 w-full md:max-w-[458px]">
          <div className={styles.topbar}>
            <img
              src={candidate.profileImg ? candidate.profileImg : harvey}
              alt="user_image"
              className={styles.imgprofile}
            />
            <div className={styles.widesection}>
              <div className="flex justify-between">
                <div className="flex flex-col items-center justify-center gap-4">
                  <p className="font-bold text-xl text-white">
                    {candidate.name === "" || !candidate.name ? (
                      <>Anonymous</>
                    ) : (
                      <>
                        {candidate.name} {candidate.surname}
                      </>
                    )}
                  </p>

                  <div className={styles.greenbtn}>
                    <div className={styles.thicktextbtn}>
                      •{" "}
                      {candidate.searchingStatus
                        ? candidate.searchingStatus === "actively looking"
                          ? "Actively looking"
                          : "Casually exploring"
                        : "Casually exploring"}
                    </div>
                  </div>
                  {history?.length > 0 && (
                    <div className="flex flex-row items-center justify-start gap-x-4 w-full mb-2">
                      <p className="italic text-sm text-white-600">
                        You have interacted with this candidate before
                      </p>
                      <MdRemoveRedEye
                        className="text-white-600 cursor-pointer"
                        size={20}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowHistoryModal(true);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.topbarsection}>
                {!candidate.cities == null || candidate.cities.length === 0 ? (
                  <p className="flex flex-row gap-y-1  items-center smallText">
                    <CiLocationOn size={20} className="mr-2" />
                    Candidate location not added
                  </p>
                ) : candidate.cities.length > 0 ? (
                  <p className="flex flex-row gap-y-1  items-center smallText">
                    <CiLocationOn size={20} className="mr-2" />

                    {candidate.cities.join(", ")}
                  </p>
                ) : (
                  <p className="flex flex-row gap-y-1  items-center smallText">
                    <CiLocationOn size={20} className="mr-2" />
                    No location added
                  </p>
                )}

                <div className="flex flex-row gap-y-1 items-center smallText">
                  <MdOutlineMenu size={20} className="mr-2" />
                  {candidate.expertise ? (
                    <>{candidate.expertise?.join(" | ")}</>
                  ) : (
                    <>
                      <p>No expertise is added</p>
                    </>
                  )}
                </div>
                <div className="flex flex-row gap-y-1 items-center smallText">
                  <PiSuitcaseLight size={20} className="mr-2" />
                  <span>{experienceTotal(candidate)}</span>
                </div>
                <div className="flex flex-row gap-y-1 items-center smallText">
                  <BiMessage size={20} className="mr-2" />
                  {candidate.selectedLanguages?.[0] ? (
                    <>{candidate.selectedLanguages?.join(" | ")}</>
                  ) : (
                    <>
                      <p>No language is added</p>
                    </>
                  )}
                </div>
              </div>
              <Stack direction="vertical" className="mt-2" gap={2}>
                {userData.isEmployer && (
                  <>
                    <LikeButton
                      isActive={isLiked || isLikedForAtLeastOneJob}
                      activeText="Add / Undo likes"
                      onClick={() => setAction(ECandidateJobAction.LIKE)}
                    />
                    {/*  <CancelButton
                      isActive={isRejected || isRejectedForAtLeastOneJob}
                      activeText="Undo or add Not interested"
                      inactiveText="Not interested"
                      onClick={() => setAction(ECandidateJobAction.REJECT)}
                    /> */}
                    <Button
                      variant="outlined"
                      className="text-white border-white flex flex-1 w-full p-0"
                      onClick={() => setAction(ECandidateJobAction.MESSAGE)}
                    >
                      <img src={IMAGES.MESSAGE_WHITE} width={18} height={18} />
                      Ask a question
                    </Button>
                  </>
                )}
              </Stack>
              <div className="flex justify-start mt-3 gap-2 w-full">
                <JobInteractionList>
                  {(jobInteractions || [])
                    .filter(
                      (ji) => ji.employerReview && ji.employerReview !== null
                    )
                    .sort((a, b) => {
                      if (a.employerReview === b.employerReview) return 0;
                      else if (
                        a.employerReview === "liked" &&
                        b.employerReview === "rejected"
                      )
                        return -1;
                      else if (
                        a.employerReview === "rejected" &&
                        b.employerReview === "liked"
                      ) {
                        return 1;
                      } else {
                        return 0;
                      }
                    })
                    .map((jobInteraction) => {
                      return (
                        <JobInteractionListItem
                          jobInteraction={jobInteraction}
                        />
                      );
                    })}
                </JobInteractionList>
              </div>
            </div>
          </div>
          <div className={"flex flex-col gap-4"}>
            <div className={styles.block}>
              <h2 className={styles.titletext}>Skills</h2>
              <div className="flex flex-col flex-wrap gap-2">
                {candidate.skills?.length > 0 ? (
                  candidate.skills?.map((skill: ESkills, i: number) => (
                    <button className={styles.buttonSkill} key={i}>
                      <img src={IMAGES.CHECK_MARK} width={15} height={14} />
                      {skill}
                    </button>
                  ))
                ) : (
                  <>No skills added</>
                )}
              </div>
            </div>

            <div className={styles.block}>
              <div className="flex items-center justify-between">
                <h2 className={styles.titletext}>Documents</h2>
              </div>
              <div className="flex items-center justify-center">
                {isIncognito ? (
                  <img src={IMAGES.LOCK} alt="Incognito" />
                ) : (
                  <div className="flex flex-col w-full gap-4">
                    <div>
                      {candidate?.cv != null && candidate?.cv !== "" ? (
                        <>
                          <div className={styles.cv_element}>
                            <div className="flex gap-4 text-sm font-medium">
                              <img
                                src={IMAGES.ATTACHMENT}
                                width={15}
                                height={14}
                                alt="attachment"
                              />
                              {candidate.name}-CV
                            </div>
                            <a
                              className={styles.buttonView}
                              href={candidate.cv}
                              target="_blank"
                            >
                              <img
                                src={IMAGES.ATTACHMENT_VIEW}
                                width={15}
                                height={15}
                                alt="attachment"
                              />
                            </a>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="mt-2 text-white">
                            No CV is uploaded
                          </div>
                        </>
                      )}
                    </div>
                    {/* Transcript */}
                    <div>
                      {candidate.transcripts != null &&
                      candidate.transcripts.length > 0 ? (
                        <>
                          {candidate.transcripts?.map(
                            (path: string, index: number) => (
                              <div className={styles.cv_element} key={index}>
                                <div className="flex gap-4 text-sm font-medium">
                                  <img
                                    src={IMAGES.ATTACHMENT}
                                    width={15}
                                    height={14}
                                  />
                                  {candidate?.name}-Transcript-{index + 1}
                                </div>
                                <a
                                  className={styles.buttonView}
                                  href={path}
                                  target="_blank"
                                >
                                  <img
                                    src={IMAGES.ATTACHMENT_VIEW}
                                    width={15}
                                    height={15}
                                  />
                                </a>
                              </div>
                            )
                          )}
                        </>
                      ) : (
                        <>
                          <div className="mt-2 text-white">
                            No Academic Transcripts are uploaded
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Medium section */}
        <div
          className={
            (styles.personalmidsection, "flex flex-col lg:flex-row w-full ")
          }
        >
          <div className={styles.largesection}>
            {/* About me section */}
            <div className={styles.abtme}>
              <div className="flex justify-between mb-3 mt-3">
                <h2 className={styles.titletext}>About the Candidate</h2>
              </div>
              {candidate?.description ? (
                candidate?.description
                  .split("\n")
                  .map((str: string, index: number) => (
                    <p className="text-white mb-2" key={index}>
                      {str}
                    </p>
                  ))
              ) : (
                <>
                  <p className="text-white">No description is added</p>
                </>
              )}
            </div>
            {/* My Experience section */}
            <div className={styles.abtme}>
              <div className="flex items-center justify-between mb-3">
                <h2 className={styles.titletext}>Experience</h2>
              </div>
              {candidate?.employmentHistory && (
                <ExperienceList
                  experiences={candidate.employmentHistory}
                  isIncognito={isIncognito}
                />
              )}
            </div>
            {/* my Education */}
            <div className={"flex flex-col gap-4 pt-[24px] border-none"}>
              <div className="flex justify-between">
                <h2 className={styles.titletext}>Candidate's Education</h2>
              </div>
              <div>
                {candidate?.master && candidate.master.length > 0 ? (
                  <>
                    <p className="text-white  mt-2 font-bold">
                      Master of Laws (LL.M.) Certificates
                    </p>
                    {candidate.master?.map((item: string, index: number) => (
                      <div className="flex items-center" key={index}>
                        <ul className="px-4 list-disc">
                          <li className="text-white">{item}</li>
                        </ul>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    <p className="text-white">No LL.M. added</p>
                  </>
                )}
              </div>
              <div>
                {candidate?.university ? (
                  <>
                    <div className="flex flex-col items-start  justify-between">
                      <p className="text-white">
                        University Name: {candidate.university}
                      </p>
                      <p className="text-white">
                        {" "}
                        - Grades: {candidate.grades}
                      </p>
                      <p className="text-white">
                        - Graduation date:{" "}
                        {flipDateString(candidate.dateOfGradutation)}
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <p className="text-white">No Education is added </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {candidateId && (
        <>
          <WhichJobModal
            action={ECandidateJobAction.MESSAGE}
            candidateId={candidateId}
            show={
              Boolean(candidateId) && action === ECandidateJobAction.MESSAGE
            }
            onClose={() => {
              setAction(null);
            }}
            onJobSelect={(job: IJob) => {
              if (candidateId) {
                navigateToConversation(candidateId, job.uid);
              }
            }}
          >
            Select the job you want to message the candidate about:
          </WhichJobModal>
          <LikeCandidateForJobsModal
            isOpen={Boolean(candidateId) && action === ECandidateJobAction.LIKE}
            candidateId={candidateId}
            headerText="Select the job you want to like / unlike the candidate for:"
            onClose={() => setAction(null)}
          />
          <RejectCandidateForJobsModal
            isOpen={
              Boolean(candidateId) && action === ECandidateJobAction.REJECT
            }
            candidateId={candidateId}
            headerText="Select the job you want to reject / unreject the candidate for:"
            onClose={() => setAction(null)}
          />
        </>
      )}
      <HistoryModal
        show={showHistoryModal}
        onHide={() => setShowHistoryModal(false)}
        history={history ?? []}
      />
    </div>
  );
};

export default RecruiterCandidatePagePreview;
