import { Button } from "react-bootstrap";
import { TbMessageCircle } from "react-icons/tb";
import ButtonTooltipWrapper from "../ButtonTooltipWrapper";
import { ICON_SIZE } from "../constants.buttonts";

interface IProps {
  disabled: boolean;
  isActive: boolean;
  onClick: () => void;
  text: string;
}

const MessageButton = ({ disabled, text, onClick }: IProps) => {
  return (
    <ButtonTooltipWrapper disabled={disabled}>
      <Button
        disabled={disabled}
        className="flex-1 text-[#131F2E] border-[#131F2E] p-1 w-full"
        variant="outlined"
        onClick={(e) => {
          e.stopPropagation();
          onClick();
        }}
      >
        <TbMessageCircle size={ICON_SIZE} />
        {text}
      </Button>
    </ButtonTooltipWrapper>
  );
};

export default MessageButton;
