import { MouseEvent } from "react";
import { Button } from "react-bootstrap";
import { TbArrowBack } from "react-icons/tb";
// @ts-ignore
import { IMAGES } from "../../../constants/images";
import ButtonTooltipWrapper from "../ButtonTooltipWrapper";

interface IProps {
  disabled?: boolean;
  isActive: boolean;
  activeText?: string;
  inactiveText?: string;
  onClick: (e: MouseEvent) => void;
  rounded?: boolean;
}

const DEFAULT_CLASSES = "";

const CancelButton = ({
  disabled = false,
  isActive = false,
  rounded = false,
  activeText = "Undo Cancel",
  inactiveText = "Cancel",
  onClick,
}: IProps) => {
  const classes = rounded ? `rounded-full ${DEFAULT_CLASSES}` : DEFAULT_CLASSES;
  return (
    <ButtonTooltipWrapper disabled={disabled}>
      <Button
        disabled={disabled}
        active={isActive}
        variant="reject"
        className={classes}
        onClick={(e) => {
          e.stopPropagation();
          onClick(e);
        }}
      >
        {isActive && <TbArrowBack />}
        {!isActive && (
          <img alt="cancel" src={IMAGES.RED_CROSS} width={20} height={20} />
        )}
        {isActive ? activeText : inactiveText}
      </Button>
    </ButtonTooltipWrapper>
  );
};

export default CancelButton;
