import { CiLocationOn } from "react-icons/ci";
import { PiSuitcaseLight } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { IMAGES } from "../constants/images.jsx";
import { deprecated_TABS_JOB_PAGE } from "../pages/JobBoardRecruiter/JobPage.util.js";

const RecruiterProfileJobCard = ({ jobData }) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col md:flex-row justify-center md:justify-between gap-8 mt-3 text-white border-t-[.8px] border-white  px-4 py-3">
      <div className="flex justify-start md:justify-start items-center gap-x-5 w-full">
        <div>
          <p className="font-bold text-[#FFC83A]">{jobData.jobTitle}</p>
          <p className="text-xs text-gray-400 mt-1">
            Posted {new Date(jobData.createdAt).toDateString()}
          </p>
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-start md:items-center justify-end w-full gap-4">
        <p className="flex items-center">
          <CiLocationOn className="mr-2" />
          {jobData?.jobCities?.join(", ")}
        </p>
        <p className="flex items-center">
          <PiSuitcaseLight className="mr-2" />
          {jobData?.jobExperienceMin}-{jobData?.jobExperienceMax} years
        </p>
        <img
          alt="yellow-arrow"
          src={IMAGES.A_YELLOW}
          width={20}
          className="cursor-pointer"
          onClick={() =>
            navigate(`/my-job/${jobData.uid}`, {
              state: {
                defaultTab: deprecated_TABS_JOB_PAGE.Potential,
              },
            })
          }
        />
      </div>
    </div>
  );
};

export default RecruiterProfileJobCard;
