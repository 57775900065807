import { useQuery } from "@tanstack/react-query";
import qs from "qs";
import { apiV1 } from "../../config/axiosConfig";
import { ICandidate } from "../../types/candidate.types";

const _getCandidates = async (queryObject = {}): Promise<ICandidate[]> => {
  const query = qs.stringify({
    ...queryObject,
  });
  const response = await apiV1.get(`candidates?${query}`);
  if (response.status < 200 || response.status > 299) {
    throw new Error("Error fetching candidates: " + response.statusText);
  }
  return response.data;
};

const useCandidates = (query = {}, enabled = true) => {
  const { data, error, isLoading } = useQuery({
    queryKey: ["candidates", query],
    queryFn: () => _getCandidates(query),
    enabled: enabled,
  });

  return {
    candidates: data,
    error,
    isCandidatesLoading: isLoading,
  };
};

export default useCandidates;
