import { useQuery } from "@tanstack/react-query";
import qs from "qs";
import { apiV1 } from "../../config/axiosConfig";

const _getJobs = async (queryObject = {}) => {
  const query = qs.stringify({
    ...queryObject,
  });
  const response = await apiV1.get(`jobs?${query}`);
  if (response.status < 200 || response.status > 299) {
    throw new Error("Error fetching jobs: " + response.statusText);
  }
  return response.data;
};

const useJobs = (query = {}, enabled = true) => {
  const { data, error, isLoading, isFetching, refetch } = useQuery({
    queryKey: ["jobs", query],
    queryFn: () => _getJobs(query),
    enabled: enabled,
    refetchOnWindowFocus: false,
  });

  return {
    jobs: data,
    error,
    isDataLoading: isLoading,
    isFetching: isFetching,
    refetch,
  };
};

export default useJobs;
