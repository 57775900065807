import { useQuery } from "@tanstack/react-query";
import { apiV1 } from "../../config/axiosConfig";
import { IJobInteraction } from "../../types/jobInteraction.types";

const _getJobInteractions = async (
  candidateId?: string
): Promise<IJobInteraction[] | undefined> => {
  const response = await apiV1.get(`job-interactions/${candidateId}`);

  if (response.status < 200 || response.status > 299) {
    throw new Error("Error fetching job interaction: " + response.statusText);
  }
  return response.data;
};

interface IProps {
  candidateId?: string;
}

export const useJobInteractionsForCandidate = ({ candidateId }: IProps) => {
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["job-interactions", candidateId],
    queryFn: () => _getJobInteractions(candidateId),
    staleTime: 1000 * 60 * 5,
    enabled: !!candidateId,
  });

  return {
    jobInteractions: data,
    error,
    isLoading: isLoading,
    refetchJobInteractions: refetch,
  };
};
