import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { ConversationProvider } from "./ConversationContext";
import { GlobalModalsProvider } from "./GlobalModalsContext";

type Props = {
  children?: React.ReactNode;
};

export const queryClient = new QueryClient();

const Providers = ({ children }: Props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <ConversationProvider>
          <GlobalModalsProvider>{children}</GlobalModalsProvider>
        </ConversationProvider>
      </QueryParamProvider>
    </QueryClientProvider>
  );
};

export default Providers;
