import { Stack } from "react-bootstrap";

interface IProps {
  children?: React.ReactNode;
}

export const JobInteractionList = ({ children }: IProps) => {
  return (
    <Stack gap={2} direction="vertical">
      {children}
    </Stack>
  );
};
