import { useQuery } from "@tanstack/react-query";
import { apiV1 } from "../../config/axiosConfig";

const getStatisticsPerJob = async (jobId?: string) => {
  if (!jobId) return;
  const response = await apiV1.get(`candidates/statistics/${jobId}`);
  if (response.status < 200 || response.status > 299) {
    throw new Error(
      "Error fetching statistics per job: " + response.statusText
    );
  }
  return response.data;
};

const useStatisticsPerJob = (jobId?: string, enabled = true) => {
  const { data, error, isLoading } = useQuery({
    queryKey: ["candidates", "statistics", { type: "job-stats", id: jobId }],
    queryFn: () => getStatisticsPerJob(jobId),
    enabled: enabled && jobId !== undefined,
  });

  return {
    statisticsPerJob: data,
    error,
    isDataLoading: isLoading,
  };
};

export default useStatisticsPerJob;
