import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiV1 } from "../../config/axiosConfig";

const _undoLikeJob = async (jobId: string) => {
  const response = await apiV1.put(`jobs/${jobId}/undo-like`);
  if (response.status < 200 || response.status > 299) {
    throw new Error("Error liking job: " + response.statusText);
  }
  return response.data;
};

interface IMutationProps {
  jobId: string;
  queryObject?: any;
}

const useUndoLikeJob = () => {
  const queryClient = useQueryClient();

  const { isPending: isUpdating, mutate: undoLikeJob } = useMutation<
    any,
    Error,
    IMutationProps,
    { previousJobs: any[] }
  >({
    mutationFn: ({ jobId }) => _undoLikeJob(jobId),
    onMutate: async ({ jobId, queryObject = {} }) => {
      let queryKey = ["jobs", queryObject];

      if (queryObject == null) {
        queryKey = ["jobs"];
      }

      await queryClient.cancelQueries({ queryKey });

      const previousJobs: any[] = queryClient.getQueryData(queryKey) ?? [];

      const job = previousJobs?.find((job: any) => job?.uid === jobId);

      if (job == null) return { previousJobs };

      const updatedJobs = previousJobs.filter((job: any) => job?.uid !== jobId);

      queryClient.setQueryData(["jobs", queryObject], updatedJobs);

      return { previousJobs };
    },
    onError: (error, data, context) => {
      queryClient.setQueryData(
        ["jobs", data.queryObject],
        context?.previousJobs
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["job-interactions"],
      });
    },
    onSettled: () => {
      if (queryClient.isMutating({ mutationKey: ["jobs"] }) === 1) {
        queryClient.invalidateQueries({ queryKey: ["jobs"] });
      }
      queryClient.invalidateQueries({
        queryKey: ["jobs", { type: "my-matches-stats" }],
      });
    },
  });

  return {
    isLoading: isUpdating,
    undoLikeJob,
  };
};

export default useUndoLikeJob;
