import { useMemo } from "react";
import { Stack } from "react-bootstrap";
import useJob from "../../../hooks/jobs/useJob";
import { IJobInteraction } from "../../../types/jobInteraction.types";
//@ts-ignore
import { IMAGES } from "../../../constants/images";
interface IProps {
  jobInteraction: IJobInteraction;
}

const getText = (jobInteraction: IJobInteraction): string => {
  const { employerReview } = jobInteraction;
  switch (employerReview) {
    case "liked":
      return "Liked for job";
    case "rejected":
      return "Rejected for job";
    default:
      return "No review";
  }
};

const getImage = (jobInteraction: IJobInteraction): any | null => {
  const { employerReview } = jobInteraction;

  switch (employerReview) {
    case "liked":
      return IMAGES.GREEN_HEART_FILLED;
    case "rejected":
      return IMAGES.RED_CROSS;
    default:
      return null;
  }
};

export const JobInteractionListItem = ({ jobInteraction }: IProps) => {
  const { job, isJobLoading } = useJob(jobInteraction.jobId);

  const [interactionText, image] = useMemo(() => {
    if (jobInteraction) {
      return [getText(jobInteraction), getImage(jobInteraction)];
    }
    return [];
  }, [jobInteraction]);

  if (isJobLoading) return <div>Loading...</div>;

  const fullText = `${interactionText} ${job!.jobTitle}`;
  return (
    <div
      style={{
        padding: "4px 16px 4px  16px",
        borderRadius: "25px",
        backgroundColor: "#5B728E",
        color: "white",
      }}
    >
      {isJobLoading && <div>Loading...</div>}
      {job && (
        <Stack gap={2} direction="horizontal">
          {image && <img alt={fullText} src={image} width={15} height={14} />}
          <p>{fullText}</p>
        </Stack>
      )}
    </div>
  );
};
