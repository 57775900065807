import { useQuery } from "@tanstack/react-query";
import { apiV1 } from "../../config/axiosConfig";
import { IJob } from "../../types/job.types";

const _getMyJobs = async (): Promise<IJob[]> => {
  const response = await apiV1.get(`my-jobs`);
  if (response.status < 200 || response.status > 299) {
    throw new Error("Error fetching my jobs: " + response.statusText);
  }
  return response.data;
};

const useMyJobs = (enabled = true) => {
  const { data, error, isLoading } = useQuery({
    queryKey: ["my-jobs", enabled],
    queryFn: () => _getMyJobs(),
    enabled: enabled,
  });

  return {
    myJobs: data,
    error,
    isMyJobsLoading: isLoading,
  };
};

export default useMyJobs;
