import { useQuery } from "@tanstack/react-query";
import { apiV1 } from "../../config/axiosConfig";
import { IJobInteraction } from "../../types/jobInteraction.types";

const _getJobInteraction = async (
  jobId?: string,
  candidateId?: string
): Promise<IJobInteraction | null> => {
  if (!jobId) return null;

  const response = await apiV1.get(`job-interactions`, {
    params: {
      jobId,
      candidateId,
    },
  });

  if (response.status < 200 || response.status > 299) {
    throw new Error("Error fetching job interaction: " + response.statusText);
  }
  return response.data;
};

interface IProps {
  jobId?: string;
  candidateId?: string;
}

export const useJobInteraction = ({ jobId, candidateId }: IProps) => {
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["job-interactions", { jobId, candidateId }],
    queryFn: () => _getJobInteraction(jobId, candidateId),
    enabled: !!jobId,
  });

  return {
    jobInteraction: data,
    error,
    isLoading: isLoading,
    refetchJobInteraction: refetch,
  };
};
