import { AiFillPlusCircle } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { DotLoader } from "react-spinners";
// @ts-ignore
import JobCard from "../../components/JobCard";
import useMyJobs from "../../hooks/jobs/useMyJobs";
import styles from "./JobBoard.module.css";
// @ts-ignore
import { IJob } from "../../types/job.types";
import { EJobPageTabs } from "./JobsTabs/JobTabs.util";

const JobBoardRecruiter = () => {
  const navigate = useNavigate();
  const { myJobs = [], isMyJobsLoading } = useMyJobs();

  const handleShowJobPage = (job: IJob, page: EJobPageTabs) => {
    navigate(`/my-job/${job.uid}?tab=${page}`);
  };

  return (
    <div className={styles.wrapper}>
      <section className={styles.container}>
        <div className={styles.header}>
          <div>
            <h1 className={styles.title}>My Jobs</h1>
            {!isMyJobsLoading && (
              <div className={styles.subtitle}>
                You have {myJobs.length} jobs
              </div>
            )}
          </div>
          <div>
            <Link to="/create-job" className={styles.createCTA}>
              <AiFillPlusCircle size={30} />
              New Job
            </Link>
          </div>
        </div>
        <div className={styles.jobs}>
          {isMyJobsLoading ? (
            <DotLoader color="#868bff" />
          ) : (
            (myJobs || []).map((job: IJob) => (
              <div key={job.uid}>
                <JobCard
                  handleShowJobPage={(tab: EJobPageTabs) => {
                    handleShowJobPage(job, tab);
                  }}
                  jobData={job}
                  onClick={() => {
                    handleShowJobPage(job, EJobPageTabs.Potential);
                  }}
                />
              </div>
            ))
          )}
        </div>
      </section>
    </div>
  );
};

export default JobBoardRecruiter;
