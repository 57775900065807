import classNames from "classnames";
import { useMemo, useState } from "react";
import { Stack } from "react-bootstrap";
import { BiMessage } from "react-icons/bi";
import { CiLocationOn } from "react-icons/ci";
import { MdOutlineMenu, MdRemoveRedEye } from "react-icons/md";
import { PiSuitcaseLight } from "react-icons/pi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
//@ts-ignore
import { sendNotification } from "../../app/functions";
import { CoverLetterIcon, CVIcon } from "../../assets/icons/code";
import { EJobStatus } from "../../constants/enums";
//@ts-ignore
import { IMAGES } from "../../constants/images";
import useGetApplication from "../../hooks/applications/useGetApplication";
import useAcceptCandidatesApplication from "../../hooks/candidates/useAcceptCandidatesApplication";
import useLikeCandidate from "../../hooks/candidates/useLikeCandidate";
import useRejectCandidate from "../../hooks/candidates/useRejectCandidate";
import useRejectCandidatesApplication from "../../hooks/candidates/useRejectCandidatesApplication";
import { useJobInteraction } from "../../hooks/jobInteractions/useJobInteraction";
import { useJobInteractionsForCandidate } from "../../hooks/jobInteractions/useJobInteractionsForCandidate";
import useInteractionHistory from "../../hooks/notifications/useInteractionHistory";
//@ts-ignore
import useCheckIfAnonymous from "../../hooks/useCheckIfAnonymous";
import useNavigateToConversation from "../../hooks/useNavigateToConversation";
import { ICandidate } from "../../types/candidate.types";
import { experienceTotal } from "../../utils/helpers";
import ApplicationStatusJobCardHeader from "../ApplicationStatusJobCardHeader/index";
import {
  CancelButton,
  ConfirmButton,
  LikeButton,
  MessageButton,
} from "../buttons";
import MatchBadge from "../MatchBadge/index";
import MatchPercentageBadge from "../MatchPercentageBadge/MatchPercentageBadge";
import HistoryModal from "../modals/HistoryModal/HistoryModal";
// @ts-ignore
import RejectModal from "../modals/RejectModal";
// @ts-ignore
import { NOTIFICATION_TYPES } from "../Notifications/index";
import styles from "./CandidateCard.module.css";

interface IProps {
  candidate: ICandidate;
  job?: any;
  showLikeButton?: boolean;
  likeButtonActiveText?: string;
  likeButtonInactiveText?: string;
  showRejectButton?: boolean;
  rejectButtonActiveText?: string;
  rejectButtonInactiveText?: string;
  showMessageButton?: boolean;
  origin: string;
  onClick?: () => void;
  onLike?: (candidateId: string) => void;
  onReject?: () => void;
  onMessage?: (candidateId: string) => void;
}

const CandidateCard = ({
  candidate,
  job,
  showLikeButton = true,
  likeButtonActiveText,
  likeButtonInactiveText,
  showRejectButton = true,
  rejectButtonActiveText,
  rejectButtonInactiveText,
  showMessageButton = true,
  origin,
  onClick,
  onLike,
  onReject,
  onMessage,
}: IProps) => {
  const userData = useSelector((state: any) => state.user.userData);
  const navigate = useNavigate();
  const { history } = useInteractionHistory(candidate?.uid);
  const { jobInteraction, isLoading } = useJobInteraction({
    jobId: job?.uid,
    candidateId: candidate?.uid,
  });

  const { jobInteractions } = useJobInteractionsForCandidate({
    candidateId: candidate?.uid,
  });

  const { navigateToConversation } = useNavigateToConversation();
  const { likeCandidate, undoLikeCandidate } = useLikeCandidate();
  const { rejectCandidate, undoRejectCandidate } = useRejectCandidate();
  const { rejectApplication } = useRejectCandidatesApplication();
  const { acceptApplication } = useAcceptCandidatesApplication();

  const { application } = useGetApplication({
    jobId: job?.uid,
    candidateId: candidate?.uid,
    enabled: origin === "applicants",
  });

  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const { incognito: isIncognito } = useCheckIfAnonymous(
    userData.uid,
    candidate.uid,
    job?.uid
  );

  const handleRejectCandidate = () => {
    if (!application) return;
    const applicationId = application.id;
    setShowRejectModal(false);
    rejectApplication(
      { applicationId: applicationId! },
      {
        onSuccess: () => {
          setShowRejectModal(false);
          sendNotification(
            candidate.uid,
            job.uid,
            job.uid,
            NOTIFICATION_TYPES.REJECT_CANDIDATE
          );
        },
      }
    );
  };

  const { isLiked, isRejected, isMatched } = useMemo(() => {
    if (jobInteraction && !isLoading) {
      const isLiked = jobInteraction.employerReview === "liked";
      const isRejected = jobInteraction.employerReview === "rejected";
      const isMatched =
        jobInteraction.candidateReview === "liked" &&
        jobInteraction.employerReview === "liked";
      return { isLiked, isRejected, isMatched };
    }
    return { isLiked: false, isRejected: false, isMatched: false };
  }, [jobInteraction, isLoading]);

  const isLikedForAtLeastOneJob = useMemo(() => {
    if (!jobInteractions) return [];
    return jobInteractions.some(
      (interaction) => interaction.employerReview === "liked"
    );
  }, [jobInteractions]);

  const handleViewCandidate = () => {
    if (onClick) {
      onClick();
      return;
    }

    navigate(`/candidate-preview/${candidate.uid}/${job?.uid ?? ""}`);
  };

  const getLikeButtonActiveText = () => {
    if (likeButtonActiveText || origin !== "candidateboard") {
      return likeButtonActiveText;
    } else if (isLikedForAtLeastOneJob && origin === "candidateboard") {
      return "Add / Undo likes";
    }
    return undefined;
  };

  const isJobNotActive = job != null && job.jobStatus !== EJobStatus.active;

  return (
    <div className={styles.container}>
      {application && (application.accepted || application.rejected) && (
        <ApplicationStatusJobCardHeader
          applicationData={{
            accepted: application.accepted,
            rejected: application.rejected,
          }}
        />
      )}
      <div
        className={classNames(
          "flex flex-column bg-white px-4 py-4 relative text-[#131F2E] cursor-pointer w-full justify-between flex-col flex-1",
          {
            "rounded-b-md": !!!application,
            "rounded-md": !!!application,
          }
        )}
      >
        <div
          className="flex flex-column h-full"
          onClick={() => {
            if (application && application.rejected) return;
            handleViewCandidate();
          }}
        >
          {application && application.rejected && (
            <div className="bg-white w-full h-full absolute top-0 left-0 bg-opacity-50 cursor-default" />
          )}
          {origin === "matches" && <MatchBadge />}
          {/* match candidate info */}
          {candidate?.isStudent && (
            <div className="absolute top-0 right-5">
              <img src={IMAGES.STUDENT} width={42} height={45} alt="" />
            </div>
          )}
          <div>
            <div className="flex items-center gap-x-2 pt-3">
              <div className="flex md:flex-row items-center w-full gap-3">
                {candidate?.profileImg ? (
                  <img
                    src={!isIncognito ? candidate.profileImg : IMAGES.U_ICON}
                    className="w-16 h-16 rounded-full border-2 border-green-600 object-cover"
                    alt=""
                  />
                ) : (
                  <img
                    src={IMAGES.U_ICON}
                    className="w-16 h-16 rounded-full border-2 border-green-600 object-cover"
                    alt=""
                  />
                )}
                <h1 className={styles.title}>
                  {isIncognito ? (
                    candidate?.name ? (
                      <>{candidate.name}</>
                    ) : (
                      <>Anonymous</>
                    )
                  ) : (
                    <>
                      {candidate.name} {candidate.surname}
                    </>
                  )}
                </h1>
              </div>
            </div>
            <div className="py-2 flex flex-col-reverse md:flex-row items-center text-[#1B1F87]/50">
              {candidate?.matchedPercentage != null && (
                <div className="w-full md:w-50 flex justify-center md:justify-end">
                  <MatchPercentageBadge
                    percentage={candidate.matchedPercentage}
                  />
                </div>
              )}
            </div>
          </div>
          {history?.length > 0 && (
            <div className="flex flex-row items-center justify-start gap-x-4 w-full mb-2">
              <p className="italic text-sm text-gray-600">
                You have interacted with this candidate before
              </p>
              <MdRemoveRedEye
                className="text-gray-600"
                size={20}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowHistoryModal(true);
                }}
              />
            </div>
          )}
          {/* match candidate experience */}
          <div className="flex justify-between w-full mt-0 py-4 border-t-2 border-gray-300 h-fit">
            <div className="flex flex-col gap-3">
              <p className="flex flex-row items-center">
                <CiLocationOn size={20} className="mr-2" />
                <span className="text-sm font-semibold">
                  {candidate?.cities == null ||
                  candidate?.cities.length === 0 ? (
                    <>Location not added</>
                  ) : (
                    <> {candidate.cities.join(", ")}</>
                  )}
                </span>
              </p>
              <p className="flex flex-row items-center">
                <MdOutlineMenu className="mr-2" />
                {candidate?.expertise.length === 0 ||
                candidate?.expertise == null ? (
                  <span className="text-sm font-semibold">
                    Expertise not disclosed
                  </span>
                ) : (
                  <span className="text-sm font-semibold">
                    {candidate?.expertise.join(", ")}{" "}
                  </span>
                )}
              </p>
              <p className="flex flex-row items-center">
                <PiSuitcaseLight className="mr-2" />
                <span className="text-sm font-semibold">
                  {experienceTotal(candidate)}
                </span>
              </p>
              <p className="flex flex-row items-center">
                <BiMessage className="mr-2" />
                {candidate?.selectedLanguages?.length === 0 ||
                candidate?.selectedLanguages == null ? (
                  <span className="text-sm font-semibold">
                    No language specified
                  </span>
                ) : (
                  <span className="text-sm font-semibold">
                    {candidate?.selectedLanguages?.join(", ")}
                  </span>
                )}
              </p>
            </div>
          </div>
          <div className={styles.description}>{candidate.description}</div>
        </div>
        {/* match candidate desc */}
        {((application &&
          application.cv &&
          application.cv !== "" &&
          origin === "applicants") ||
          (application && application.coverLetter)) && (
          <div className="flex flex-column justify-center items-center w-full gap-y-2 py-8">
            <div className="flex items-center justify-center gap-x-4 w-full md:w-[50%] h-10">
              {application &&
                application.cv &&
                application.cv !== "" &&
                origin === "applicants" && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(application.cv, "_blank");
                    }}
                    className="flex flex-column items-center justify-center cursor-pointer  px-4 py-1  bg-[#D9D9D9]/[40%]  rounded-md mx-2"
                  >
                    <CVIcon />
                    <div className="justify-center flex-column column items-center">
                      <span className="text-xs text-[#363BB199] font-bold">
                        CV
                      </span>
                      <span className="text-xs text-[#363BB199] font-bold">
                        &nbsp;
                      </span>
                    </div>
                  </button>
                )}
              {application &&
                application.coverLetter != null &&
                application.coverLetter !== "" &&
                origin === "applicants" && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      const coverLetter = application.coverLetter;
                      if (coverLetter) {
                        window.open(coverLetter ?? undefined, "_blank");
                      }
                    }}
                    className="flex flex-column items-center justify-center cursor-pointer  px-4 py-1  bg-[#D9D9D9]/[40%]  rounded-md mx-2"
                  >
                    <CoverLetterIcon />
                    <div className="justify-center flex-column column items-center">
                      <span className="text-xs text-[#363BB199] font-bold">
                        Cover
                      </span>
                      <span className="text-xs text-[#363BB199] font-bold">
                        Letter
                      </span>
                    </div>
                  </button>
                )}
            </div>
          </div>
        )}
        {!isMatched && application && origin === "applicants" && (
          <Stack gap={2} direction="vertical">
            {!application.accepted && !application.rejected && (
              <Stack gap={2} direction="horizontal">
                <CancelButton
                  isActive={true}
                  disabled={isJobNotActive}
                  rounded
                  inactiveText="Reject"
                  activeText="Reject"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowRejectModal(true);
                  }}
                />
                <ConfirmButton
                  disabled={isJobNotActive}
                  text="Accept"
                  rounded
                  onClick={(e) => {
                    acceptApplication(
                      {
                        applicationId: application.id,
                      },
                      {
                        onSuccess: () => {
                          sendNotification(
                            candidate.uid,
                            job.uid,
                            job.uid,
                            NOTIFICATION_TYPES.ACCEPT_CANDIDATE
                          );
                        },
                      }
                    );
                  }}
                />
              </Stack>
            )}
            <MessageButton
              isActive={!isJobNotActive}
              disabled={isJobNotActive || application.rejected}
              text="Ask a question"
              onClick={() => {
                if (onMessage) {
                  onMessage(candidate.uid);
                } else {
                  navigateToConversation(candidate.uid, job?.uid);
                }
              }}
            />
          </Stack>
        )}
        <Stack gap={2}>
          <Stack direction="horizontal" gap={2}>
            {showRejectButton && (
              <CancelButton
                isActive={isRejected || isMatched}
                disabled={isJobNotActive}
                activeText={rejectButtonActiveText ?? "Undo not interested"}
                inactiveText={rejectButtonInactiveText ?? "Not interested"}
                onClick={(e) => {
                  if (onReject) {
                    onReject();
                    return;
                  }
                  !isRejected
                    ? rejectCandidate({
                        candidateId: candidate.uid,
                        jobId: job?.uid,
                      })
                    : undoRejectCandidate({
                        candidateId: candidate.uid,
                        jobId: job?.uid,
                      });
                }}
              />
            )}
            {showLikeButton && (
              <LikeButton
                isActive={
                  isLiked ||
                  (isLikedForAtLeastOneJob && origin === "candidateboard")
                }
                disabled={isJobNotActive}
                activeText={getLikeButtonActiveText()}
                inactiveText={likeButtonInactiveText ?? undefined}
                onClick={(e) => {
                  if (onLike) {
                    onLike(candidate.uid);
                    return;
                  }
                  if (isLiked) {
                    undoLikeCandidate({
                      candidateId: candidate.uid,
                      jobId: job?.uid,
                    });
                  } else {
                    likeCandidate(
                      { candidateId: candidate.uid, jobId: job?.uid },
                      {
                        onSuccess: (newData) => {
                          if (newData.isMatch) {
                            sendNotification(
                              job.userid,
                              candidate.uid,
                              job.uid,
                              NOTIFICATION_TYPES.MATCH_JOB
                            );
                            sendNotification(
                              candidate.uid,
                              job.uid,
                              job.uid,
                              NOTIFICATION_TYPES.MATCH_CANDIDATE
                            );
                          } else {
                            sendNotification(
                              candidate.uid,
                              job.uid,
                              job.uid,
                              NOTIFICATION_TYPES.LIKE_CANDIDATE
                            );
                          }
                        },
                      }
                    );
                  }
                }}
              />
            )}
          </Stack>
          {showMessageButton && (
            <MessageButton
              isActive={!isJobNotActive}
              disabled={isJobNotActive}
              text="Ask a question"
              onClick={() => {
                if (onMessage) {
                  onMessage(candidate.uid);
                  return;
                } else {
                  navigateToConversation(candidate.uid, job?.uid);
                }
              }}
            />
          )}
        </Stack>
      </div>
      <RejectModal
        show={showRejectModal}
        onHide={() => setShowRejectModal(false)}
        onReject={handleRejectCandidate}
      />
      <HistoryModal
        show={showHistoryModal}
        onHide={() => setShowHistoryModal(false)}
        history={history ?? []}
      />
    </div>
  );
};

export default CandidateCard;
