import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import CandidateCard from "../../../components/CandidateCard/CandidateCard";
import { ICandidate } from "../../../types/candidate.types";
import { EJobPageTabs } from "./JobTabs.util";

interface IProps {
  tab: EJobPageTabs;
  candidates?: ICandidate[];
  job: any;
}

interface IButtonText {
  active?: string;
  inactive?: string;
}

const JobPageContent = ({ candidates, job, tab }: IProps) => {
  const navigate = useNavigate();

  const handleCandidateClick = useCallback(
    (candidateId: string, activeTab: EJobPageTabs) => {
      navigate(`/candidate-preview/${candidateId}/${job.uid}`, {
        state: {
          jobId: job.uid,
          jobTab: activeTab,
        },
      });
    },
    [job, navigate]
  );

  const { showLikeButton, showRejectButton, showMessageButton } =
    useMemo(() => {
      const showLikeButton = [
        EJobPageTabs.Potential,
        EJobPageTabs.LikedByMe,
        EJobPageTabs.LikesMyJob,
      ].includes(tab);
      const showRejectButton = [
        EJobPageTabs.Potential,
        EJobPageTabs.Matches,
        EJobPageTabs.LikesMyJob,
        EJobPageTabs.NotInterested,
      ].includes(tab);

      const showMessageButton = [
        EJobPageTabs.Potential,
        EJobPageTabs.Matches,
        EJobPageTabs.LikedByMe,
        EJobPageTabs.NotInterested,
        EJobPageTabs.LikesMyJob,
      ].includes(tab);

      return { showLikeButton, showRejectButton, showMessageButton };
    }, [tab]);

  const rejectedButtonText: IButtonText = useMemo(() => {
    switch (tab) {
      case EJobPageTabs.Matches:
        return { active: "Undo match" };
      default:
        return {};
    }
  }, [tab]);

  return (
    <div className="flex flex-column mt-2">
      {/* candidates cards container */}
      <div className="grid grid-cols-1 lg:grid-cols-2 lg:grid-cols-3 gap-5 text-black relative auto-rows-max grid-auto-column gap-x-5 mt-3">
        {/* candidate card */}
        {(candidates || []).map((candidate) => {
          return (
            <CandidateCard
              key={candidate.uid}
              candidate={candidate}
              job={job}
              origin={tab}
              showLikeButton={showLikeButton}
              showRejectButton={showRejectButton}
              showMessageButton={showMessageButton}
              rejectButtonActiveText={rejectedButtonText.active}
              onClick={() => handleCandidateClick(candidate.uid, tab)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default JobPageContent;
