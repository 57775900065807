import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
//@ts-ignore
import { fetchAndCreateConversation } from "../app/functions";
//@ts-ignore
import { userSelector } from "../app/userRedux/userSlice";

const useNavigateToConversation = () => {
  const navigate = useNavigate();
  //TODO: fix any
  const user: any = useSelector(userSelector);

  const handleNavigate = async (
    conversationPartnerId: string,
    jobId: string
  ) => {
    console.log(
      "useNavigateToConversation conversationPartnerId",
      conversationPartnerId
    );
    console.log("useNavigateToConversation jobId", jobId);

    if (!user) return;
    const startedChatById = user.uid;
    const candidateId = user.isEmployer ? conversationPartnerId : user.uid;
    const employerId = user.isEmployer ? user.uid : conversationPartnerId;

    const conversation = await fetchAndCreateConversation(
      startedChatById,
      candidateId,
      employerId,
      jobId
    );

    navigate("/chat-room", {
      state: { conversationId: conversation.id },
    });
  };

  return {
    navigateToConversation: handleNavigate,
  };
};

export default useNavigateToConversation;
