export const CoverLetterIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="43"
    height="43"
    viewBox="0 0 43 43"
    fill="none"
  >
    <path
      d="M22.4238 14.7812V5.71094H9.74219V37.2891H33.2578V16.5449H24.1875C23.7197 16.5449 23.2711 16.3591 22.9404 16.0284C22.6096 15.6976 22.4238 15.249 22.4238 14.7812ZM21.5 28.3027C21.5 28.4875 21.3488 28.6387 21.1641 28.6387H13.4375C13.2527 28.6387 13.1016 28.4875 13.1016 28.3027V26.2871C13.1016 26.1023 13.2527 25.9512 13.4375 25.9512H21.1641C21.3488 25.9512 21.5 26.1023 21.5 26.2871V28.3027ZM29.8984 20.5762V22.5918C29.8984 22.7766 29.7473 22.9277 29.5625 22.9277H13.4375C13.2527 22.9277 13.1016 22.7766 13.1016 22.5918V20.5762C13.1016 20.3914 13.2527 20.2402 13.4375 20.2402H29.5625C29.7473 20.2402 29.8984 20.3914 29.8984 20.5762Z"
      fill="#E6F7FF"
    />
    <path
      d="M35.8865 12.1189L26.8498 3.08223C26.5979 2.83027 26.2577 2.6875 25.9008 2.6875H8.0625C7.31924 2.6875 6.71875 3.28799 6.71875 4.03125V38.9688C6.71875 39.712 7.31924 40.3125 8.0625 40.3125H34.9375C35.6808 40.3125 36.2812 39.712 36.2812 38.9688V13.0722C36.2812 12.7152 36.1385 12.3709 35.8865 12.1189ZM25.2793 5.78652L33.1822 13.6895H25.2793V5.78652ZM33.2578 37.2891H9.74219V5.71094H22.4238V14.7812C22.4238 15.249 22.6096 15.6976 22.9404 16.0284C23.2711 16.3591 23.7197 16.5449 24.1875 16.5449H33.2578V37.2891Z"
      fill="#8689D0"
    />
    <path
      d="M13.1016 20.5762V22.5918C13.1016 22.7766 13.2527 22.9277 13.4375 22.9277H29.5625C29.7473 22.9277 29.8984 22.7766 29.8984 22.5918V20.5762C29.8984 20.3914 29.7473 20.2402 29.5625 20.2402H13.4375C13.2527 20.2402 13.1016 20.3914 13.1016 20.5762ZM21.1641 25.9512H13.4375C13.2527 25.9512 13.1016 26.1023 13.1016 26.2871V28.3027C13.1016 28.4875 13.2527 28.6387 13.4375 28.6387H21.1641C21.3488 28.6387 21.5 28.4875 21.5 28.3027V26.2871C21.5 26.1023 21.3488 25.9512 21.1641 25.9512Z"
      fill="#8689D0"
    />
  </svg>
);
