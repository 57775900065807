import classNames from "classnames";
import { IoMdClose } from "react-icons/io";
import styles from "./GradePercentages.module.css";

const grades = ["50-65%", "65-75%", "75-85%", "85-100%"];

interface IProps {
  onSelect: (grade: string) => void;
  selectedGrade: string;
}

const GradePercentages = ({ onSelect, selectedGrade }: IProps) => {
  const isSelected = (grade: string) => selectedGrade === grade;

  return (
    <div className={styles.container}>
      {grades.map((grade) => (
        <button
          key={grade}
          className={classNames(styles.item, {
            [styles.isSelected]: isSelected(grade),
          })}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => onSelect(grade)}
          value={grade}
        >
          {grade}
          {isSelected(grade) && <IoMdClose />}
        </button>
      ))}
    </div>
  );
};

export default GradePercentages;
