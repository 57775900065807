import { useQuery } from "@tanstack/react-query";
import { apiV1 } from "../../config/axiosConfig";
import { IApplication } from "../../types/application.types";

//TODO: create type for Application
const _getApplication = async (
  jobId: string,
  candidateId: string
): Promise<IApplication | undefined> => {
  console.log("Fetching applications");
  const response = await apiV1.get(
    `candidates/${candidateId}/applications/${jobId}`
  );
  if (response.status < 200 || response.status > 299) {
    throw new Error("Error fetching candidate: " + response.statusText);
  }
  return response.data;
};

interface IProps {
  jobId: string;
  candidateId: string;
  enabled: boolean;
}

const useGetApplication = ({ jobId, candidateId, enabled }: IProps) => {
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["applications", { jobId, candidateId }],
    queryFn: () => _getApplication(jobId, candidateId),
    enabled: !!jobId && !!candidateId && enabled,
  });

  return {
    application: data,
    error,
    isCandidateLoading: isLoading,
    refetch,
  };
};

export default useGetApplication;
