import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { apiV1 } from "../../config/axiosConfig";
import { IJobInteraction } from "../../types/jobInteraction.types";

interface IMutationProps {
  jobId: string;
  candidateId: string;
}

const _likeCandidate = async ({ jobId, candidateId }: IMutationProps) => {
  const response = await apiV1.put(`/candidates/${candidateId}/like`, {
    jobId,
  });
  if (response.status < 200 || response.status > 299) {
    throw new Error("Error liking candidate: " + response.statusText);
  }
  return response.data;
};

const _undoLikeCandidate = async ({ jobId, candidateId }: IMutationProps) => {
  const response = await apiV1.put(`/candidates/${candidateId}/undo-like`, {
    jobId,
  });
  if (response.status < 200 || response.status > 299) {
    throw new Error("Error liking candidate: " + response.statusText);
  }
  return response.data;
};

const useLikeCandidate = () => {
  const queryClient = useQueryClient();

  const onSuccess = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: ["candidates"],
    });
    queryClient.invalidateQueries({
      queryKey: ["job-interactions"],
    });
  }, [queryClient]);

  const onMutate = async ({
    jobId,
    candidateId,
    employerReview,
  }: IMutationProps & { employerReview: "liked" | null }) => {
    const jobInteractionQK = ["job-interactions", { jobId, candidateId }];

    await queryClient.cancelQueries({
      queryKey: jobInteractionQK,
    });

    const previousJobInteraction: IJobInteraction | undefined =
      queryClient.getQueryData(["job-interactions", { jobId, candidateId }]);

    if (!previousJobInteraction) return undefined;
    const updatedJobInteraction = {
      ...previousJobInteraction,
      employerReview,
    };

    queryClient.setQueryData(jobInteractionQK, updatedJobInteraction);
  };

  const { isPending: isLiking, mutate: likeCandidate } = useMutation<
    any,
    Error,
    IMutationProps,
    { previousJobInteractions: IJobInteraction[] }
  >({
    mutationFn: _likeCandidate,
    onMutate: (params) => onMutate({ ...params, employerReview: "liked" }),
    onSuccess,
  });

  const { isPending: isUndoingLike, mutate: undoLikeCandidate } = useMutation<
    any,
    Error,
    IMutationProps
  >({
    mutationFn: _undoLikeCandidate,
    onMutate: (params) => onMutate({ ...params, employerReview: null }),
    onSuccess,
  });

  return {
    isLoading: isLiking || isUndoingLike,
    likeCandidate,
    undoLikeCandidate,
  };
};

export default useLikeCandidate;
