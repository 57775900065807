import { ReactNode, useCallback } from "react";
import { Button, Modal, OverlayTrigger, Stack, Tooltip } from "react-bootstrap";
import { TbArrowBack } from "react-icons/tb";
import { DotLoader } from "react-spinners";
import { useJobInteractionsForCandidate } from "../../hooks/jobInteractions/useJobInteractionsForCandidate";
import useMyJobs from "../../hooks/jobs/useMyJobs";
import { IJob } from "../../types/job.types";
// @ts-ignore
import { IMAGES } from "../../constants/images";
import { ECandidateJobAction } from "./SelectJobForAction/selectForJobActions.types";
interface IProps {
  children: ReactNode;
  candidateId: string;
  show: boolean;
  action: ECandidateJobAction;
  onClose: () => void;
  onJobSelect: (job: IJob) => void;
}

const WhichJobModal = ({
  children,
  candidateId,
  show,
  action,
  onClose,
  onJobSelect,
}: IProps) => {
  const { myJobs, isMyJobsLoading } = useMyJobs();
  const { jobInteractions, isLoading: isLoadingJobInteractions } =
    useJobInteractionsForCandidate({ candidateId });

  const handleChooseJob = useCallback(
    (job: IJob) => {
      onJobSelect(job);
      onClose();
    },
    [onJobSelect, onClose]
  );

  const getStatusForJob = useCallback(
    (job: IJob, status: "liked" | "rejected") => {
      if (!jobInteractions) return false;
      const matchingJob = jobInteractions.find(
        (ji) => ji.jobId === job.uid && ji.employerReview === status
      );
      if (matchingJob) return true;
    },
    [jobInteractions]
  );

  const getButtonTextAndImage = (job: IJob) => {
    const isLikedForJob = getStatusForJob(job, "liked");
    const isRejectedForJob = getStatusForJob(job, "rejected");

    let altText: string = "";
    let buttonText: string = "";
    let imageSrc: any | null = null;

    if (action === ECandidateJobAction.LIKE) {
      if (isLikedForJob) {
        altText = "Undo like";
        imageSrc = IMAGES.GREEN_HEART_FILLED;
        buttonText = `Undo like candidate for job ${job.jobTitle}`;
      } else {
        altText = "Like";
        imageSrc = IMAGES.GREEN_HEART;
        buttonText = `Like candidate for the job ${job.jobTitle}`;
      }
    } else if (action === ECandidateJobAction.REJECT) {
      if (isRejectedForJob) {
        altText = "Undo not interested";
        imageSrc = "hackyhackhack";
        buttonText = `Undo not interested for job ${job.jobTitle}`;
      } else {
        altText = "Not interested";
        imageSrc = IMAGES.RED_CROSS;
        buttonText = `Not interested for job ${job.jobTitle}`;
      }
    } else if (action === ECandidateJobAction.MESSAGE) {
      altText = "Message";
      imageSrc = IMAGES.MESSAGE;
      buttonText = `Message candidate for job ${job.jobTitle}`;
    }

    const finalImage =
      imageSrc === "hackyhackhack" ? (
        <TbArrowBack color="red" width={15} height={14} />
      ) : (
        <img alt={altText} src={imageSrc} width={15} height={14} />
      );
    return { buttonText, image: finalImage };
  };

  if (isLoadingJobInteractions) return <DotLoader color="#868bff" />;
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Choose a job</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isMyJobsLoading ? (
          <DotLoader color="#868bff" />
        ) : (myJobs || []).length === 0 ? (
          <>You'll need to create a job first!</>
        ) : (
          <>
            <p>{children}</p>
            <Stack direction="vertical" gap={2}>
              {(myJobs || []).map((job: IJob, i: number) => (
                <OverlayTrigger
                  key={i}
                  placement="top"
                  overlay={
                    job.jobStatus !== "active" ? (
                      <Tooltip
                        placement="top"
                        className="in fixed"
                        id="tooltip-right"
                      >
                        The job is currently not active
                      </Tooltip>
                    ) : (
                      <></>
                    )
                  }
                >
                  <Button
                    disabled={job.jobStatus !== "active"}
                    key={job.uid}
                    onClick={() => handleChooseJob(job)}
                    variant="outlined"
                    className={`text-black border-2 border-black p-6 w-full `}
                  >
                    {getButtonTextAndImage(job).image}
                    {getButtonTextAndImage(job).buttonText}
                  </Button>
                </OverlayTrigger>
              ))}
            </Stack>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WhichJobModal;
