export const CVIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M32.5 2.5H7.5C6.80859 2.5 6.25 3.05859 6.25 3.75V36.25C6.25 36.9414 6.80859 37.5 7.5 37.5H32.5C33.1914 37.5 33.75 36.9414 33.75 36.25V3.75C33.75 3.05859 33.1914 2.5 32.5 2.5ZM22.3438 5.3125H26.0938V13.5117L24.2773 12.1875L22.3438 13.5703V5.3125ZM9.0625 34.6875V5.3125H20V16.9102C20 17.0391 20.0391 17.168 20.1172 17.2734C20.1646 17.3405 20.2249 17.3974 20.2944 17.4411C20.364 17.4847 20.4416 17.514 20.5226 17.5275C20.6036 17.5409 20.6865 17.5382 20.7664 17.5194C20.8463 17.5006 20.9217 17.4661 20.9883 17.418L24.2617 15.0781L27.4414 17.3984C27.5469 17.4766 27.6758 17.5195 27.8086 17.5195C28.1523 17.5195 28.4336 17.2383 28.4336 16.8945V5.3125H30.9336V34.6875H9.0625Z"
      fill="#8689D0"
    />
    <path
      d="M26.0937 13.5117V5.3125H22.3437V13.5703L24.2773 12.1875L26.0937 13.5117Z"
      fill="#E6F7FF"
    />
    <path
      d="M28.4336 5.3125V16.8945C28.4336 17.2383 28.1523 17.5195 27.8086 17.5195C27.6758 17.5195 27.5469 17.4766 27.4414 17.3984L24.2617 15.0781L20.9883 17.418C20.9217 17.4661 20.8463 17.5006 20.7664 17.5194C20.6865 17.5382 20.6036 17.5409 20.5226 17.5275C20.4416 17.514 20.364 17.4847 20.2944 17.4411C20.2249 17.3974 20.1646 17.3405 20.1172 17.2734C20.0391 17.168 20 17.0391 20 16.9102V5.3125H9.0625V34.6875H30.9336V5.3125H28.4336Z"
      fill="#E6F7FF"
    />
  </svg>
);
