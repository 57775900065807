import { IJob } from "../../../types/job.types";
import JobCard from "../JobCard/JobCard";
import styles from "./JobList.module.css";

interface IProps {
  jobs?: IJob[];
  onClick?: (jobId: string) => any;
  queryObject?: any;
  refetchJobs?: () => any;
}

const JobList = ({
  jobs = [],
  onClick = () => null,
  queryObject,
  refetchJobs,
}: IProps) => {
  return (
    <div className={styles.container}>
      <ul className={styles.list}>
        {jobs.map((job) => {
          return (
            <li key={job.uid}>
              <JobCard
                job={job}
                queryObject={queryObject}
                refetchJobs={refetchJobs}
                onClick={onClick(job.uid)}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default JobList;
