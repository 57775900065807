import { useNavigate } from "react-router-dom";
import styles from "./EmployerSignUpMessage.module.css";

const EmployerSignUpMessage = () => {
  const navigate = useNavigate();

  return (
    <div className="flex justify-center items-center h-lvh py-[100px] text-black overflow-y-auto">
      <div className={styles.boxcontainer}>
        <div className="flex flex-column items-center w-full my-3">
          <div className="text-center">
            <h1 className="font-bold text-3xl">Thank you for registering.</h1>
          </div>
          <div className="text-center my-2">
            <p>
              <b>Thank you for creating an account on JustLawyers!</b>
            </p>
            <p>
              You will receive an email once your account has been approved.
              Please note this process may take up to 1 business day.
            </p>
            <div className="text-center mt-4">
              <button
                className="bg-[#46D6EA]  py-2 px-5 rounded-md font-bold border-1 border-[#46D6EA] cursor-pointer hover:bg-transparent"
                onClick={() => navigate("/")}
              >
                Back to home
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployerSignUpMessage;
