import { useCallback } from "react";
import useRejectCandidate from "../../../hooks/candidates/useRejectCandidate";
import { useJobInteractionsForCandidate } from "../../../hooks/jobInteractions/useJobInteractionsForCandidate";
import { IJob } from "../../../types/job.types";
import WhichJobModal from "../WhichJobModal";
import { ECandidateJobAction } from "./selectForJobActions.types";

interface IProps {
  headerText: string;
  candidateId: string;
  isOpen: boolean;
  onClose?: () => void;
}

export const RejectCandidateForJobsModal = ({
  headerText,
  candidateId,
  isOpen,
  onClose,
}: IProps) => {
  const { rejectCandidate, undoRejectCandidate } = useRejectCandidate();

  const { jobInteractions } = useJobInteractionsForCandidate({
    candidateId: candidateId,
  });

  const onJobSelect = useCallback(
    (job: IJob) => {
      const jobInteraction = (jobInteractions || []).find(
        (interaction) => interaction.jobId === job.uid
      );
      if (jobInteraction && jobInteraction.employerReview === "rejected") {
        undoRejectCandidate({
          candidateId: candidateId,
          jobId: job.uid,
        });
      } else {
        rejectCandidate({
          candidateId: candidateId,
          jobId: job.uid,
        });
      }
    },
    [jobInteractions, candidateId, rejectCandidate, undoRejectCandidate]
  );

  return (
    <WhichJobModal
      action={ECandidateJobAction.REJECT}
      show={isOpen && Boolean(candidateId)}
      candidateId={candidateId}
      onClose={() => onClose?.()}
      onJobSelect={onJobSelect}
    >
      {headerText}
    </WhichJobModal>
  );
};
