import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiV1 } from "../../config/axiosConfig";

const _acceptApplication = async ({
  applicationId,
}: {
  applicationId: string;
}) => {
  const response = await apiV1.put(`candidates/applications/accept`, {
    applicationId: applicationId,
  });
  if (response.status < 200 || response.status > 299) {
    throw new Error(
      "Error updating application accept status: " + response.statusText
    );
  }
  return response.data;
};

const useAcceptCandidatesApplication = () => {
  const queryClient = useQueryClient();

  const { isPending: isUpdating, mutate: acceptApplication } = useMutation({
    mutationFn: _acceptApplication,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["candidates"],
      });
      queryClient.invalidateQueries({
        queryKey: ["applications"],
      });
    },
  });

  return {
    isLoading: isUpdating,
    acceptApplication,
  };
};

export default useAcceptCandidatesApplication;
