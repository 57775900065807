import { AiFillDelete, AiOutlineUpload } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { saveData } from "../../app/dataSlice.js";
import { editUser } from "../../app/functions.js";
import { updateInitialData } from "../../app/initialDataSlice";
import anonymousIcon from "../../assets/icons/anonymous.png";
import GradePercentages from "../../components/ApplicationStatusJobCardHeader/GradePercentages/GradePercentages";
import { llmOptions } from "../../constants/Data.js";
import {
  checkFileSize,
  deleteFile,
  ENUM_FILE_TYPE,
  uploadFile,
} from "../../utils/crudFiles.ts";
import styles from "./CandidateData.module.css";
import Layout from "./Layout.jsx";

const CandidateData1 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const initialData = useSelector((state) => state.initialData);

  const handleEditUser = async (userData) => {
    await editUser(userData, dispatch, saveData);
  };

  const handleUploadCV = async (event) => {
    const file = event.target.files[0];

    if (!file || !checkFileSize(file)) {
      return;
    }
    const filePath = `cvs/${file.size}${file.name}`;

    await uploadFile(
      userData.uid,
      initialData,
      file,
      filePath,
      dispatch,
      updateInitialData,
      ENUM_FILE_TYPE.CV
    );
  };

  const handleDeleteCV = async () => {
    await deleteFile(
      userData.uid,
      initialData,
      initialData.cvPath,
      dispatch,
      updateInitialData,
      ENUM_FILE_TYPE.CV
    );
  };

  const handleUploadTranscripts = async (event) => {
    const files = Array.from(event.target.files);

    const uploadPromises = files
      .filter((file) => file && checkFileSize(file))
      .map(async (file) => {
        const filePath = `transcripts/${file.size}${file.name}`;

        await uploadFile(
          initialData.uid,
          initialData,
          file,
          filePath,
          dispatch,
          updateInitialData,
          ENUM_FILE_TYPE.TRANSCRIPTS
        );
      });

    await Promise.all(uploadPromises);
  };

  const handleDeleteTranscripts = async (index) => {
    const filePath = initialData.transcriptsPath[index];

    await deleteFile(
      initialData.uid,
      initialData,
      filePath,
      dispatch,
      updateInitialData,
      ENUM_FILE_TYPE.TRANSCRIPTS,
      index
    );
  };

  const nextWindow = (e) => {
    navigate("/candidatedata2");
  };

  const handleChangeLlmList = (selectedOptions) => {
    const newLlmList = selectedOptions
      ? selectedOptions.map((option) => option.label)
      : [];
    dispatch(updateInitialData({ master: newLlmList }));
  };

  const previousWindow = () => {
    navigate("/candidatedata0");
  };

  return (
    <Layout
      onBack={previousWindow}
      onNext={nextWindow}
      onSkip={nextWindow}
      step={2}
    >
      <section className={styles.section}>
        <h2 className={styles.title}>Education</h2>
        <p>Please provide your most relevant education.</p>
        <section className={styles.subsection}>
          <h2 className={styles.title}>Master in Law</h2>
          <div className={styles.field}>
            <label className="font-bold">University</label>
            <CreatableSelect
              isClearable
              placeholder="Select university..."
              className="basic-single"
              classNamePrefix="select"
              defaultValue={""}
              name="university"
              options={llmOptions}
              onChange={(selectedOption) => {
                const newUniversity = selectedOption
                  ? selectedOption.label
                  : "";
                dispatch(updateInitialData({ university: newUniversity }));
              }}
              value={
                initialData.university
                  ? {
                      label: initialData.university,
                      value: initialData.university,
                    }
                  : null
              }
            />
          </div>
          <div className={styles.field}>
            <label>Average Grades</label>
            <GradePercentages
              onSelect={(grade) => {
                let _grade = grade;
                if (initialData.grades === grade) {
                  _grade = undefined;
                }
                dispatch(updateInitialData({ grades: _grade }));
              }}
              selectedGrade={initialData.grades}
            />
          </div>
          <div className={styles.field}>
            <label>Graduation date</label>
            <input
              type="date"
              className={styles.input}
              onChange={(e) =>
                dispatch(
                  updateInitialData({ dateOfGradutation: e.target.value })
                )
              }
              value={initialData.dateOfGradutation}
            />
          </div>
        </section>

        <section className={styles.subsection}>
          <h2 className={styles.title}>Additional Master in Laws (LL.M.)</h2>

          <div className={styles.field}>
            <label>University</label>
            <CreatableSelect
              isClearable
              placeholder="Select university..."
              defaultValue={[]}
              isMulti
              name="university"
              options={llmOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handleChangeLlmList}
              value={initialData.master?.map((university) => ({
                label: university,
                value: university,
              }))}
            />
          </div>

          <div className={styles.field}>
            <label>Graduation date</label>

            <input
              type="date"
              className={styles.input}
              onChange={(e) =>
                dispatch(
                  updateInitialData({
                    dateOfGradutationLLM: e.target.value,
                  })
                )
              }
              value={initialData.dateOfGradutationLLM}
            />
          </div>
        </section>
      </section>

      <section className={styles.section}>
        <h2 className={styles.title}>
          Attachments
          <img src={anonymousIcon} className={styles.anonymousIcon} />
        </h2>
        <p>
          Please provide your current or most relevant application documents.
        </p>

        <div className="my-2">
          <label
            htmlFor="cv"
            className={`flex items-center justify-center bg-[#374b64] rounded-md text-white py-2 ${initialData.cvPath == null && "cursor-pointer"} w-full`}
          >
            {initialData.cvPath != null ? (
              <>
                <AiFillDelete
                  size={22}
                  className="mr-3 cursor-pointer"
                  onClick={async () => handleDeleteCV()}
                />
                Delete uploaded CV
              </>
            ) : (
              <>
                <AiOutlineUpload size={22} className="mr-3" />
                Click to Upload CV
              </>
            )}
          </label>
          {initialData.cvPath == null && (
            <input
              id="cv"
              type="file"
              name="cv"
              className="hidden"
              onChange={async (event) => handleUploadCV(event)}
              accept=".pdf, .txt, .doc, .docx"
            />
          )}
        </div>

        <div className="space-y-2">
          <label
            htmlFor="transcripts"
            className="flex items-center justify-center bg-[#374b64] rounded-md text-white py-2 cursor-pointer w-full"
          >
            <AiOutlineUpload size={22} className="mr-3" />
            Upload Academic Transcripts
          </label>
          {initialData.transcriptsPath?.length > 0 &&
            initialData.transcriptsPath?.map((path, index) => (
              <div key={index} className="flex justify-between w-full">
                <div className="flex items-center">
                  <AiFillDelete
                    size={22}
                    className="mr-3 cursor-pointer"
                    onClick={async () => handleDeleteTranscripts(index)}
                  />

                  <span>{`Academic Transcript ${index + 1}`}</span>
                </div>
              </div>
            ))}
          <input
            id="transcripts"
            type="file"
            className="hidden"
            onChange={async (event) => handleUploadTranscripts(event)}
            accept=".pdf, .txt, .doc, .docx"
          />
        </div>
      </section>
    </Layout>
  );
};

export default CandidateData1;
