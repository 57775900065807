import { Button, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
// @ts-ignore
import { IMAGES } from "../constants/images.jsx";
import useCandidate from "../hooks/candidates/useCandidate.js";
import useNavigateToConversation from "../hooks/useNavigateToConversation";
// @ts-ignore
import Logo from "./firms/Logo/index.js";

interface IProps {
  show: boolean;
  handleClose: () => void;
  data?: any;
}

const RecruiterMatchModal = ({ show, handleClose, data }: IProps) => {
  const { navigateToConversation } = useNavigateToConversation();
  const initialData = useSelector((state) => (state as any).initialData);
  const { candidate } = useCandidate(
    data?.reference,
    {},
    data?.reference != null
  );

  if (candidate == null || data == null) return null;

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Body className="flex flex-column items-center justify-center">
        <Row className="items-center justify-center gap-y-2 text-center mb-2 ">
          <img src={IMAGES.CONE} className=" max-w-fit h-full" />
          <p className="font-bold text-2xl">Hooray!</p>
        </Row>
        <Row className="items-center justify-between border-2 border-[#46D6EA] rounded-full my-2 w-[45%]">
          <div className="flex items-center justify-center w-14 h-14 md:w-20 md:h-20 border-2 border-[#46D6EA] rounded-full overflow-hidden p-0">
            <Logo src={initialData?.firmLogo} size="100%" />
          </div>
          <div className="flex items-center justify-center w-14 h-14 md:w-20 md:h-20 border-2 border-[#46D6EA] rounded-full overflow-hidden p-0">
            <img
              src={candidate?.profileImg ?? IMAGES.U_ICON}
              className="w-full h-full object-cover rounded-full"
            />
          </div>
        </Row>
        <Row className="items-center justify-center text-center  w-full ">
          <p className="text-4xl font-bold">You have a match!</p>
          <p className="">You've matched with {candidate.name}!</p>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row className="flex items-center justify-center gap-y-2">
          <Row className="w-75 my-1">
            <Button
              className=" font-bold bg-[#46D6EA] text-black px-5 hover:bg-transparent"
              onClick={() => {
                navigateToConversation(candidate.uid, data.jobId);
                handleClose();
              }}
            >
              Contact Candidate
            </Button>
          </Row>
          <Row className="w-75 my-1">
            <Button
              className=" font-bold border-[#46D6EA] text-black px-5 hover:bg-transparent "
              onClick={handleClose}
            >
              Continue browsing
            </Button>
          </Row>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default RecruiterMatchModal;
