import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface IProps {
  children: React.ReactNode;
  disabled: boolean;
  text?: string;
}

const ButtonTooltipWrapper = ({
  children,
  disabled,
  text = "You can't take action on this candidate because the job is no longer active.",
}: IProps) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        disabled ? (
          <Tooltip placement="top" className="in fixed" id="tooltip-top">
            {text}
          </Tooltip>
        ) : (
          <></>
        )
      }
    >
      <div className="w-full flex-1">{children}</div>
    </OverlayTrigger>
  );
};

export default ButtonTooltipWrapper;
