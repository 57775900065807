import { useMemo, useState } from "react";
import { Alert } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { BiMessage } from "react-icons/bi";
import { CgFlagAlt } from "react-icons/cg";
import { CiLocationOn, CiSettings } from "react-icons/ci";
import { LuUser2 } from "react-icons/lu";
import { MdOutlineMenu } from "react-icons/md";
import { PiCaretCircleLeftFill, PiSuitcaseLight } from "react-icons/pi";
import { VscGlobe } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { updateInitialData } from "../../app/initialDataSlice.js";
import { CancelButton, LikeButton } from "../../components/buttons";
import CandidateLawFirmView from "../../components/CandidateLawFirmView.jsx";
import Logo from "../../components/firms/Logo";
import MatchPercentageBadge from "../../components/MatchPercentageBadge/MatchPercentageBadge";
import ModalCandidateJobApply from "../../components/modalCandidateJobApply/modalCandidateJobApply.jsx";
import SanitizeString from "../../components/SanitizeString";
import { EJobStatus } from "../../constants/enums";
import { IMAGES } from "../../constants/images";
import { useJobInteraction } from "../../hooks/jobInteractions/useJobInteraction";
import useJob from "../../hooks/jobs/useJob";
import useLikeJob from "../../hooks/jobs/useLikeJob";
import useRejectJob from "../../hooks/jobs/useRejectJob";
import useUndoLikeJob from "../../hooks/jobs/useUndoLikeJob";
import useUndoRejectJob from "../../hooks/jobs/useUndoRejectJob";
import useNavigateToConversation from "../../hooks/useNavigateToConversation";

const CandidateJobDetailsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state: routingState } = useLocation();
  const params = useParams();
  const { jobUid } = params;
  const initialData = useSelector((state) => state.initialData);
  const { likeJob } = useLikeJob();
  const { undoLikeJob } = useUndoLikeJob();
  const { rejectJob } = useRejectJob();
  const { undoRejectJob } = useUndoRejectJob();
  const { job, isJobLoading, refetch } = useJob(jobUid);

  const [showFirmPage, setShowFirmPage] = useState(false);
  const { jobInteraction, isLoading } = useJobInteraction({
    jobId: params?.jobUid,
  });

  const { isLiked, isRejected } = useMemo(() => {
    if (jobInteraction && !isLoading) {
      const isLiked = jobInteraction.candidateReview === "liked";
      const isRejected = jobInteraction.candidateReview === "rejected";
      const isMatched =
        jobInteraction.candidateReview === "liked" &&
        jobInteraction.employerReview === "liked";
      return { isLiked, isRejected, isMatched };
    }
    return { isLiked: false, isRejected: false, isMatched: false };
  }, [jobInteraction, isLoading]);

  const [applied, setApplied] = useState(false);
  const { navigateToConversation } = useNavigateToConversation();

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleShowModal = () => {
    dispatch(updateInitialData({ selectedJob: job }));
    setShowModal(true);
  };

  const handleGoBack = () => {
    if (routingState?.conversationId) {
      navigate("/chat-room", {
        state: { conversationId: routingState.conversationId },
      });
    } else if (routingState?.tab) {
      navigate("/my-matches", {
        state: { defaultTab: routingState.tab },
      });
    } else {
      window.history.back();
    }
  };

  if (isJobLoading) {
    return;
  }

  return (
    <>
      {job == null || job?.jobStatus !== EJobStatus.active ? (
        <div
          className="flex flex-column items-center h-screen text-white"
          style={{
            height: "calc(100vh - 62px)", // Adjust the height based on your navbar height
          }}
        >
          <div className="flex mt-5 mb-20 mx-3 w-[90%] lg:w-[80%]">
            <div
              className="flex items-center cursor-pointer"
              onClick={handleGoBack}
            >
              <PiCaretCircleLeftFill
                size={38}
                className="mr-2 text-[#46D6EA] duration-75 hover:scale-125"
              />
              Back
            </div>
          </div>
          <Alert variant="info">
            <h2 className="text-xl font-bold">This job no longer exists.</h2>
          </Alert>
        </div>
      ) : (
        <>
          {showFirmPage ? (
            <>
              <CandidateLawFirmView setShowFirmPage={setShowFirmPage} />
            </>
          ) : (
            <>
              <div
                className="flex flex-column items-center h-screen text-white"
                style={{
                  height: "calc(100vh - 62px)", // Adjust the height based on your navbar height
                }}
              >
                <div className="flex mt-5 mb-20 mx-3 w-[90%] lg:w-[80%]">
                  <div
                    className="flex items-center cursor-pointer"
                    onClick={handleGoBack}
                  >
                    <PiCaretCircleLeftFill
                      size={38}
                      className="mr-2 text-[#46D6EA] duration-75 hover:scale-125"
                    />
                    Back
                  </div>
                </div>
                <div className="flex flex-col lg:flex-row mt-2 mx-3 w-[90%] lg:w-[80%] gap-x-5 pb-10">
                  {/* left */}
                  <div className="w-full lg:w-[70%] bg-white rounded-md text-[#131F2E] h-fit m-0 lg:mr-2 mb-3 pb-10 ">
                    {/* background image */}
                    <div className="relative">
                      {/* firm logo */}
                      <div className="flex justify-between w-full px-4 md:px-5 relative ">
                        <div className="flex justify-center md:justify-start w-[85%] md:w-fit absolute -bottom-10">
                          <Logo shadow src={job?.firmLogo} />
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-center md:justify-end px-4 md:px-5 mt-[30px] md:mt-[0px]">
                      <div className="flex items-center justify-center mt-3 gap-x-3 w-full space-between md:max-w-[50%]">
                        {!isLiked && (
                          <CancelButton
                            isActive={isRejected}
                            activeText="Undo Not Interested"
                            inactiveText="Not Interested"
                            onClick={(e) => {
                              if (isRejected) {
                                undoRejectJob({
                                  jobId: job.uid,
                                  userId: initialData.uid,
                                });
                              } else {
                                rejectJob({
                                  jobId: job.uid,
                                  userId: initialData.uid,
                                });
                              }
                            }}
                          />
                        )}
                        {!isRejected && (
                          <LikeButton
                            isActive={isLiked}
                            onClick={(e) => {
                              if (isLiked) {
                                undoLikeJob({
                                  jobId: job.uid,
                                  userId: initialData.uid,
                                  jobUserId: job.userid,
                                });
                              } else {
                                likeJob({
                                  jobId: job.uid,
                                  userId: initialData.uid,
                                  jobUserId: job.userid,
                                });
                              }
                            }}
                          />
                        )}
                        <ModalCandidateJobApply
                          showModal={showModal}
                          handleClose={handleCloseModal}
                          setApplied={(e) => setApplied(e)}
                          job_id={job?.uid}
                          refetchJobs={refetch}
                        />
                      </div>
                    </div>

                    {/* job info */}
                    <div className="px-8 md:px-8 py-2 mt-[10px] md:mt-[50px]">
                      <div className="flex justify-between items-center">
                        <div className="mt-1">
                          <p className="font-bold text-[22px]">
                            {job?.jobTitle}
                            {/* Job title */}
                          </p>
                          <p className="text-[15px] text-[#667588] my-2">
                            Created {new Date(job.createdAt).toDateString()}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col md:flex-row gap-2 text-xs mt-3">
                        <MatchPercentageBadge
                          percentage={job.matchedPercentage}
                        />
                      </div>

                      {/* job more info */}
                      <div className="flex flex-column w-full py-6 text-[15px] font-medium">
                        <p className="flex items-center">
                          <div>
                            <CiLocationOn className="mr-2" />
                          </div>
                          <div>
                            {job?.jobCities == "" || job?.jobCities == null ? (
                              <>Location not disclosed</>
                            ) : (
                              <>{job?.jobCities.join(", ")}</>
                            )}
                          </div>
                        </p>
                        <p className="flex items-center">
                          <MdOutlineMenu className="mr-2" />
                          {job?.jobExpertise == "" ||
                          job?.jobExpertise == null ? (
                            <>No expertise specified</>
                          ) : (
                            <>{job?.jobExpertise?.join(", ")}</>
                          )}
                        </p>
                        <p className="flex items-center">
                          <CiSettings className="mr-2" />
                          {job?.jobSkills == "" ||
                          job?.jobSkills == null ||
                          job?.jobSkills.length === 0 ? (
                            <>No skills specified</>
                          ) : (
                            <>{job?.jobSkills?.join(", ")}</>
                          )}
                        </p>
                        <p className="flex items-center">
                          <PiSuitcaseLight className="mr-2" />
                          <>
                            {job?.isStudent ? (
                              <>Internship or student job</>
                            ) : (
                              <>
                                {job?.jobExperienceMin} -{" "}
                                {job?.jobExperienceMax} years of experience
                              </>
                            )}
                          </>
                        </p>

                        <p className="flex items-center">
                          <BiMessage className="mr-2" />
                          {job?.jobLanguages == "" ||
                          job?.jobLanguages == null ? (
                            <>No language provided</>
                          ) : (
                            <>{job?.jobLanguages.join(", ")}</>
                          )}
                        </p>
                      </div>
                      <div className="flex items-center justify-start gap-5 space-between">
                        {!applied &&
                          !job?.applicants?.includes(initialData.uid) &&
                          !isRejected && (
                            <Button
                              className="md:w-40"
                              variant="secondary"
                              onClick={(e) => {
                                e.stopPropagation(); // Stop the propagation here
                                // e.preventDefault();
                                handleShowModal(); // Or your button click logic here
                              }}
                            >
                              Apply
                            </Button>
                          )}
                        <div
                          className="flex flex-0  items-center justify-center cursor-pointer"
                          onClick={() =>
                            navigateToConversation(job?.userid, job?.uid)
                          }
                        >
                          <img
                            src={IMAGES.GREY_MESSAGE}
                            alt="message"
                            className="mr-2"
                          />
                          <p className="flex items-center mb-1 cursor-pointer text-[#131F2E]/60">
                            Ask a question
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* Job Description */}
                    <div className="px-4 md:px-5 py-2 border-t-[0.5px] border-gray-200 my-2">
                      <h4 className="text-[#131F2E]/50 uppercase my-4 font-bold">
                        full description
                      </h4>
                      <SanitizeString
                        className="list-disc"
                        content={job?.jobDescription ?? ""}
                      />
                    </div>
                    {/* Job Requirement */}
                    <div className="px-4 md:px-5 py-2 border-t-[0.5px] border-gray-200 my-2 sm:px-4 md:px-5">
                      <h4 className="text-[#131F2E]/50 uppercase my-4 font-bold">
                        REQUIREMENTS
                      </h4>
                      <div className="text-justify">
                        <ul>
                          <li className="py-2">
                            <strong>Expertise: &nbsp;</strong>
                            {job?.jobExpertise?.join(", ")}
                          </li>
                          <li className="py-2">
                            <strong>Skills: &nbsp;</strong>
                            {job?.jobSkills?.join(", ")}
                          </li>
                          <li className="py-2">
                            <strong>Minimum experience: &nbsp;</strong>
                            {job?.jobExperienceMin}
                          </li>
                          <li className="py-2">
                            <strong>Maximum experience: &nbsp;</strong>
                            {job?.jobExperienceMax}
                          </li>
                          <li className="py-2">
                            <strong>Required languages: &nbsp;</strong>
                            {job?.jobLanguages?.join(", ")}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* right */}
                  <div className="w-full lg:w-[436px] m-0 lg:ml-2 bg-[#364A63] p-4 rounded-md text-[16px] h-fit">
                    <div className="pb-3 border-b-[0.5px] border-gray-300">
                      <h2 className="text-xl font-medium mb-1">
                        More about this law firm
                      </h2>
                    </div>
                    <div className="pb-3 border-b-[0.5px] border-gray-300 ">
                      <p className="flex items-center mt-4">
                        <CgFlagAlt className="mr-1" />

                        {job?.companyName}
                      </p>
                      <p className="flex items-center my-3">
                        <LuUser2 className="mr-1" />
                        {job?.firmSize}
                      </p>
                      <p className="flex items-center">
                        <VscGlobe className="mr-1" />
                        <a
                          href={job?.firmWebsite}
                          target="_blank"
                          className="overflow-x-clip"
                        >
                          {job?.firmWebsite}
                        </a>
                      </p>
                    </div>
                    <div>
                      <h3 className="my-4 uppercase text-[15px]">overview</h3>
                      <SanitizeString
                        className="text-white"
                        content={job?.aboutCompany ?? ""}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default CandidateJobDetailsPage;
