import classNames from "classnames";
import styles from "./Button.module.css";

export const buttonType = {
  DEFAULT: "default",
  LINK: "link",
};

interface IProps {
  children: React.ReactNode;
  className?: string;
  type?: string;
}

const Button = ({
  children,
  className,
  type = buttonType.DEFAULT,
  ...buttonProps
}: IProps) => {
  const classes = classNames(
    styles.button,
    { [styles["button--link"]]: type === buttonType.LINK },
    className
  );

  return (
    <button className={classes} {...buttonProps}>
      {children}
    </button>
  );
};

export default Button;
