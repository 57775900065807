import { isToday, isYesterday } from "date-fns";
import { useMemo, useState } from "react";
import { Button, Stack } from "react-bootstrap";
import { BiMessage } from "react-icons/bi";
import { CiLocationOn } from "react-icons/ci";
import { MdOutlineMenu } from "react-icons/md";
import { PiSuitcaseLight } from "react-icons/pi";
import { TbMessageCircle } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// @ts-ignore
import { updateInitialData } from "../app/initialDataSlice";
import { useJobInteraction } from "../hooks/jobInteractions/useJobInteraction";
import useLikeJob from "../hooks/jobs/useLikeJob";
import useRejectJob from "../hooks/jobs/useRejectJob";
import useUndoLikeJob from "../hooks/jobs/useUndoLikeJob";
import useUndoRejectJob from "../hooks/jobs/useUndoRejectJob";
import useNavigateToConversation from "../hooks/useNavigateToConversation";
import { IJob } from "../types/job.types.js";
import ApplicationStatusJobCardHeader from "./ApplicationStatusJobCardHeader/index";
import { CancelButton, LikeButton, MessageButton } from "./buttons/index.js";
import styles from "./CandidateJobBoardCard.module.css";
// @ts-ignore
import Logo from "./firms/Logo/Logo";
import MatchBadge from "./MatchBadge/index";
import MatchPercentageBadge from "./MatchPercentageBadge/MatchPercentageBadge";
// @ts-ignore
import ModalCandidateJobApply from "./modalCandidateJobApply/modalCandidateJobApply";
import SanitizeString from "./SanitizeString/index";

interface IProps {
  job: IJob;
  onClick?: (jobId: string) => void;
  queryObject: any;
  refetchJobs: () => void;
}

const CandidateJobBoardCard = ({
  job,
  queryObject,
  onClick,
  refetchJobs,
}: IProps) => {
  const navigate = useNavigate();
  const initialData = useSelector((state: any) => state.initialData);
  const { jobInteraction, isLoading } = useJobInteraction({ jobId: job.uid });

  const { likeJob } = useLikeJob();
  const { undoLikeJob } = useUndoLikeJob();
  const { rejectJob } = useRejectJob();
  const { undoRejectJob } = useUndoRejectJob();

  const { isLiked, isRejected, isMatched } = useMemo(() => {
    if (jobInteraction && !isLoading) {
      const isLiked = jobInteraction.candidateReview === "liked";
      const isRejected = jobInteraction.candidateReview === "rejected";
      const isMatched =
        jobInteraction.candidateReview === "liked" &&
        jobInteraction.employerReview === "liked";
      return { isLiked, isRejected, isMatched };
    }
    return { isLiked: false, isRejected: false, isMatched: false };
  }, [jobInteraction, isLoading]);

  const [showModal, setShowModal] = useState(false);
  const [applied, setApplied] = useState(
    job?.applicants?.includes(initialData.uid)
  );

  const dispatch = useDispatch();

  const { navigateToConversation } = useNavigateToConversation();

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleShowModal = () => {
    dispatch(updateInitialData({ selectedJob: job }));
    setShowModal(true);
  };

  const goToJob = () => {
    if (onClick) {
      onClick(job.uid);
      return;
    }

    navigate(`/job-details-page/${job.uid}`);
  };

  const formatDate = (dateString: string) => {
    const formattedDate = new Date(dateString);

    // Check if the date is today or yesterday
    if (isToday(formattedDate)) {
      return "Today";
    } else if (isYesterday(formattedDate)) {
      return "Yesterday";
    } else {
      // If not today or yesterday, display the actual date
      const options: { year: "numeric"; month: "long"; day: "numeric" } = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return formattedDate.toLocaleDateString(undefined, options);
    }
  };

  return (
    <>
      <div className="flex flex-col h-full max-w-[450px] relative">
        {isMatched && !applied && <MatchBadge />}
        {job?.applicationData != null && (
          <ApplicationStatusJobCardHeader
            applicationData={job.applicationData}
          />
        )}
        <div
          className={`bg-white ${job?.applicationData != null ? "rounded-b-md" : "rounded-md"} px-4 py-3 text-sm mb-3 flex flex-col justify-between text-[#19293D] flex-1 h-full cursor-pointer relative `}
        >
          {job?.applicationData?.rejected && (
            <div className="bg-white w-full h-full absolute top-0 left-0 bg-opacity-50 cursor-default" />
          )}
          {/* image and info */}
          <div className="flex flex-col justify-between grow h-full">
            <div className="flex justify-between">
              <div className="flex items-center">
                <Logo src={job?.firmLogo} size={50} />
                <p
                  className="ml-4 font-medium"
                  style={{
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                  }}
                >
                  {job?.companyName}
                </p>
              </div>

              {!applied && (
                <Button
                  className="pressable"
                  variant="secondary"
                  style={{ width: "fit-content" }}
                  onClick={() => {
                    handleShowModal();
                  }}
                >
                  Apply
                </Button>
              )}
            </div>

            <div
              onClick={() => {
                if (job?.applicationData?.rejected ?? false) return;
                goToJob();
              }}
              className="flex flex-col grow h-full"
            >
              <div className="border-b-2 border-gray-300 mt-2 pb-3">
                <p
                  className={styles.title}
                  style={{
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                  }}
                >
                  {job?.jobTitle === "" ? "Job title" : job?.jobTitle}
                </p>
                <p className="text-xs mt-1 my-1 mb-4 text-[#131F2E]/50">
                  Created: {formatDate(job?.createdAt)}
                </p>
                <MatchPercentageBadge
                  inline
                  percentage={job.matchedPercentage}
                />
              </div>
              <div className="my-3 flex font-medium flex-col gap-1">
                <div className="flex items-center">
                  <div>
                    <CiLocationOn size={20} className="mr-2" />
                  </div>
                  <div
                    style={{
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                    }}
                  >
                    {job?.jobCities.length === 0 || job?.jobCities == null
                      ? "Location not disclosed"
                      : job?.jobCities.join(", ")}
                  </div>
                </div>
                <div className="flex items-center">
                  <div>
                    <PiSuitcaseLight size={20} className="mr-2" />
                  </div>
                  <div
                    style={{
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                    }}
                  >
                    {job?.isStudent ? (
                      <>Internship or student job</>
                    ) : (
                      <>
                        {job?.jobExperienceMin} - {job?.jobExperienceMax} years
                        of experience
                      </>
                    )}
                  </div>
                </div>
                <div className="flex items-center">
                  <div>
                    <BiMessage size={20} className="mr-2" />
                  </div>
                  <div
                    style={{
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                    }}
                  >
                    {job?.jobLanguages.length === 0 || job?.jobLanguages == null
                      ? "No language provided"
                      : job?.jobLanguages.join(", ")}
                  </div>
                </div>
                <div className="flex items-center">
                  <div>
                    <MdOutlineMenu size={0} className="mr-2" />
                  </div>
                  <div
                    style={{
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                    }}
                  >
                    {job?.jobExpertise.length === 0 || job?.jobExpertise == null
                      ? "No expertise specified"
                      : job?.jobExpertise.join(", ")}
                  </div>
                </div>
              </div>
              <div className="hidden sm:block">
                <SanitizeString
                  content={job?.jobDescription ?? ""}
                  className="list-disc mb-2 font-normal text-[#131F2E]/70 text-wrap break-normal line-clamp-2"
                />
              </div>
            </div>
          </div>

          {!applied && !job?.applicants?.includes(initialData.uid) && (
            <Stack gap={2}>
              <Button
                className="md:hidden pressable"
                variant={"outlined"}
                onClick={() => {
                  goToJob();
                }}
              >
                See full job description
              </Button>
              <Stack direction="horizontal" gap={2}>
                {!isLiked && (
                  <CancelButton
                    isActive={isRejected}
                    activeText="Undo Not Interested"
                    inactiveText="Not Interested"
                    onClick={() => {
                      if (isRejected) {
                        undoRejectJob({
                          jobId: job.uid,
                          queryObject: queryObject,
                        });
                      } else {
                        rejectJob({
                          jobId: job.uid,
                          queryObject: queryObject,
                        });
                      }
                    }}
                  />
                )}
                {!isRejected && (
                  <LikeButton
                    isActive={isLiked}
                    activeText="Undo Like"
                    inactiveText="Like"
                    onClick={() => {
                      if (isLiked) {
                        undoLikeJob({
                          jobId: job.uid,
                          queryObject: queryObject,
                        });
                      } else {
                        likeJob({
                          jobId: job.uid,
                          userId: initialData.uid,
                          jobUserId: job.userid,
                          queryObject: queryObject,
                        });
                      }
                    }}
                  />
                )}
              </Stack>
              <MessageButton
                isActive={true}
                disabled={false}
                text="Ask a question"
                onClick={() => {
                  navigateToConversation(job.userid, job.uid);
                }}
              />
            </Stack>
          )}
          {(applied || job?.applicants?.includes(initialData.uid)) && (
            <div
              className="flex mt-3 items-center justify-center text-center pressable"
              onClick={() => {
                if (job?.applicationData?.rejected ?? false) return;
                navigateToConversation(job.userid, job.uid);
              }}
            >
              <p className="flex items-center mb-1 cursor-pointer text-[#131F2E]/60 pressable">
                <TbMessageCircle size={20} className="mr-2" />
                Start the conversation
              </p>
            </div>
          )}
        </div>
      </div>
      <ModalCandidateJobApply
        showModal={showModal}
        handleClose={handleCloseModal}
        setApplied={(e: any) => setApplied(e)}
        job_id={job?.uid}
        refetchJobs={refetchJobs}
      />
    </>
  );
};

export default CandidateJobBoardCard;
