import { MouseEvent } from "react";
import { Button } from "react-bootstrap";
// @ts-ignore
import { IMAGES } from "../../../constants/images";
import ButtonTooltipWrapper from "../ButtonTooltipWrapper";

interface IProps {
  disabled: boolean;
  onClick: (e: MouseEvent) => void;
  text?: string;
  rounded?: boolean;
}

const ConfirmButton = ({
  disabled,
  text = "Confirm",
  rounded = false,
  onClick,
}: IProps) => {
  const classes = rounded ? "rounded-full" : "";
  return (
    <ButtonTooltipWrapper disabled={disabled}>
      <Button
        disabled={disabled}
        variant="like"
        className={classes}
        onClick={(e) => {
          e.stopPropagation();
          onClick(e);
        }}
      >
        <img
          alt="confirm"
          src={IMAGES.CHECK_MARK_BLUE}
          color=""
          width={15}
          height={14}
        />
        {text}
      </Button>
    </ButtonTooltipWrapper>
  );
};

export default ConfirmButton;
