const tabClass = (isActive: boolean) => {
  return isActive
    ? "text-[#46D6EA] border-b-[2px] border-[#46D6EA] pb-1 font-bold"
    : "text-white";
};

interface IProps {
  isActive: boolean;
  title: string;
  amount: number;
}

const TabTitle = ({ isActive, title, amount }: IProps) => {
  const tabClasses = tabClass(isActive);
  return (
    <p className="text-white">
      <span className={`pt-4 mr-3 ${tabClasses}`}>{title}</span>({amount})
    </p>
  );
};

export default TabTitle;
