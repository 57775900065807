import { isToday, isYesterday } from "date-fns";
import { useMemo, useState } from "react";
import { Button, Stack } from "react-bootstrap";
import { BiMessage } from "react-icons/bi";
import { CiLocationOn } from "react-icons/ci";
import { MdOutlineMenu } from "react-icons/md";
import { PiSuitcaseLight } from "react-icons/pi";
import { TbMessageCircle } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DotLoader } from "react-spinners";
//@ts-ignore
import { updateInitialData } from "../../../app/initialDataSlice";
import useGetApplication from "../../../hooks/applications/useGetApplication";
import { useJobInteraction } from "../../../hooks/jobInteractions/useJobInteraction";
import useLikeJob from "../../../hooks/jobs/useLikeJob.js";
import useRejectJob from "../../../hooks/jobs/useRejectJob";
import useUndoLikeJob from "../../../hooks/jobs/useUndoLikeJob";
import useUndoRejectJob from "../../../hooks/jobs/useUndoRejectJob";
import useNavigateToConversation from "../../../hooks/useNavigateToConversation";
import { IJob } from "../../../types/job.types";
import ApplicationStatusJobCardHeader from "../../ApplicationStatusJobCardHeader/index";
import MatchBadge from "../../MatchBadge/index.js";
import MatchPercentageBadge from "../../MatchPercentageBadge/MatchPercentageBadge";
import SanitizeString from "../../SanitizeString/index.js";
import { CancelButton, LikeButton, MessageButton } from "../../buttons/index";
//@ts-ignore
import Logo from "../../firms/Logo/index";
//@ts-ignore
import ModalCandidateJobApply from "../../modalCandidateJobApply/modalCandidateJobApply";
import styles from "./JobCard.module.css";

interface IProps {
  job: IJob;
  onClick?: (jobId: string) => void;
  queryObject: { userId: string; queryField: string };
  refetchJobs: any;
}

const JobCard = ({ job, onClick, queryObject, refetchJobs }: IProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { jobInteraction, isLoading } = useJobInteraction({ jobId: job.uid });

  const initialData = useSelector((state: any) => state.initialData);
  const { likeJob } = useLikeJob();
  const { undoLikeJob } = useUndoLikeJob();
  const { rejectJob } = useRejectJob();
  const { undoRejectJob } = useUndoRejectJob();
  const [showModal, setShowModal] = useState(false);
  const { navigateToConversation } = useNavigateToConversation();

  const { application } = useGetApplication({
    candidateId: initialData.uid,
    jobId: job.uid,
    enabled: queryObject.queryField === "applicants",
  });

  const [applied, setApplied] = useState(
    job?.applicants?.includes(initialData.uid)
  );

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleShowModal = () => {
    dispatch(updateInitialData({ selectedJob: job }));
    setShowModal(true);
  };

  const goToJob = () => {
    if (onClick) {
      onClick(job.uid);
      return;
    }

    navigate(`/job-details-page/${job.uid}`);
  };

  const formatDate = (dateString: string) => {
    const formattedDate = new Date(dateString);

    // Check if the date is today or yesterday
    if (isToday(formattedDate)) {
      return "Today";
    } else if (isYesterday(formattedDate)) {
      return "Yesterday";
    } else {
      // If not today or yesterday, display the actual date
      const options = { year: "numeric", month: "long", day: "numeric" };
      // TODO: fix any here
      return formattedDate.toLocaleDateString(undefined, options as any);
    }
  };

  const { isLiked, isRejected, isMatched } = useMemo(() => {
    if (jobInteraction && !isLoading) {
      const isLiked = jobInteraction.candidateReview === "liked";
      const isRejected = jobInteraction.candidateReview === "rejected";
      const isMatched =
        jobInteraction.candidateReview === "liked" &&
        jobInteraction.employerReview === "liked";
      return { isLiked, isRejected, isMatched };
    }
    return { isLiked: false, isRejected: false, isMatched: false };
  }, [jobInteraction, isLoading]);

  if (!job) return <DotLoader color="#868bff" />;

  return (
    <>
      <div className={styles.container}>
        {isMatched && !applied && <MatchBadge />}
        {application && (
          <ApplicationStatusJobCardHeader
            applicationData={{
              accepted: application.accepted,
              rejected: application.rejected,
            }}
          />
        )}
        <div className={styles.content}>
          {application && application.rejected && (
            <div className="bg-white w-full h-full absolute top-0 left-0 bg-opacity-50 cursor-default" />
          )}
          {/* image and info */}
          <div className="flex flex-col justify-between">
            <div className="flex justify-between">
              <div className="flex items-center">
                <Logo src={job?.firmLogo} size={50} />
                <p
                  className="ml-4 font-medium text-[17px]"
                  style={{
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                  }}
                >
                  {job?.companyName}
                </p>
              </div>

              {!applied && (
                <Button
                  variant="secondary"
                  style={{ width: "fit-content" }}
                  onClick={() => {
                    handleShowModal();
                  }}
                >
                  Apply
                </Button>
              )}
            </div>
            <div
              onClick={() => {
                if ((application && application.rejected) ?? false) return;
                goToJob();
              }}
              className="flex flex-col"
            >
              <div className="border-b-2 border-gray-300 mt-2 pb-3">
                <h1
                  style={{
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                  }}
                  className={styles.title}
                >
                  {job.jobTitle}
                </h1>
                <p className="text-xs my-1 mb-4 text-[#131F2E]/50">
                  Created: {formatDate(job?.createdAt)}
                </p>
                <MatchPercentageBadge
                  inline
                  percentage={job.matchedPercentage}
                />
              </div>
              <div className="my-3 flex font-medium flex-col gap-1">
                <div className="flex items-center">
                  <CiLocationOn size={20} className="mr-2" />
                  <span
                    style={{
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                    }}
                  >
                    {job.jobCities == null || job.jobCities.length === 0
                      ? "Location not disclosed"
                      : job?.jobCities.join(", ")}
                  </span>
                </div>
                <div className="flex items-center">
                  <PiSuitcaseLight size={20} className="mr-2" />
                  <span
                    style={{
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                    }}
                  >
                    {job?.isStudent ? (
                      <>Internship or student job</>
                    ) : (
                      <>
                        {job?.jobExperienceMin} - {job?.jobExperienceMax} years
                        of experience
                      </>
                    )}
                  </span>
                </div>
                <div className="flex items-center">
                  <BiMessage size={20} className="mr-2" />
                  <span
                    style={{
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                    }}
                  >
                    {job?.jobLanguages == null || job?.jobLanguages.length === 0
                      ? "No language provided"
                      : job?.jobLanguages.join(", ")}
                  </span>
                </div>
                <div className="flex items-center ">
                  <MdOutlineMenu size={0} className="mr-2" />
                  <span
                    style={{
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                    }}
                  >
                    {job?.jobExpertise == null || job.jobExpertise.length === 0
                      ? "No expertise specified"
                      : job?.jobExpertise.join(", ")}
                  </span>
                </div>
              </div>
              <SanitizeString
                content={job?.jobDescription ?? ""}
                className="max-h-[40px] list-disc mb-2 font-normal text-[#131F2E]/70 text-wrap break-normal line-clamp-2"
              />
            </div>
          </div>
          <Stack direction="vertical" gap={2}>
            <Button
              className="md:mt-2 md:hidden pressable w-full"
              variant={"outlined"}
              onClick={() => {
                goToJob();
              }}
            >
              See full job description
            </Button>
            {!applied && !job?.applicants?.includes(initialData.uid) && (
              <Stack direction="vertical" gap={2}>
                <div className="flex items-center justify-center mt-3 gap-x-3 w-full space-between">
                  {isMatched && (
                    <CancelButton
                      isActive={isMatched}
                      activeText="Undo Match"
                      onClick={() => {
                        undoLikeJob({
                          jobId: job.uid,
                          queryObject: queryObject,
                        });
                      }}
                    />
                  )}
                  {!isLiked && (
                    <CancelButton
                      disabled={false}
                      isActive={isRejected}
                      activeText="Undo Not Interested"
                      inactiveText="Not Interested"
                      onClick={(e) => {
                        if (isRejected) {
                          undoRejectJob({
                            jobId: job.uid,
                            queryObject: queryObject,
                          });
                        } else {
                          rejectJob({
                            jobId: job.uid,
                            queryObject: queryObject,
                          });
                        }
                      }}
                    />
                  )}
                  {!isRejected && !isMatched && (
                    <LikeButton
                      disabled={false}
                      isActive={isLiked}
                      activeText="Undo Like"
                      inactiveText="Like"
                      onClick={(e) => {
                        if (isLiked) {
                          undoLikeJob({
                            jobId: job.uid,
                            queryObject: queryObject,
                          });
                        } else {
                          likeJob({
                            jobId: job.uid,
                            userId: initialData.uid,
                            jobUserId: job.userid,
                            queryObject: queryObject,
                          });
                        }
                      }}
                    />
                  )}
                </div>
                <MessageButton
                  isActive={true}
                  disabled={false}
                  text="Ask a question"
                  onClick={() => {
                    navigateToConversation(job.userid, job.uid);
                  }}
                />
              </Stack>
            )}
            {(applied || job?.applicants?.includes(initialData.uid)) && (
              <div
                className="flex mt-3 items-center justify-center text-center pressable"
                onClick={() => {
                  if (application && application.rejected) return;
                  navigateToConversation(job.userid, job.uid);
                }}
              >
                <p className="flex items-center mb-1 cursor-pointer text-[#131F2E]/60 pressable">
                  <TbMessageCircle size={20} className="mr-2" />
                  Start the conversation
                </p>
              </div>
            )}
          </Stack>
        </div>
      </div>
      <ModalCandidateJobApply
        showModal={showModal}
        handleClose={handleCloseModal}
        setApplied={(e: any) => setApplied(e)}
        job_id={job?.uid}
        refetchJobs={refetchJobs}
      />
    </>
  );
};

export default JobCard;
