import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { saveData } from "../../app/dataSlice.js";
import { editUser } from "../../app/functions.js";
import Content1 from "./Content1.jsx";
import Content2 from "./Content2.jsx";

const ModalRecuriterEditProfile = ({ show, handleClose }) => {
  const dispatch = useDispatch();
  const initialData = useSelector((state) => state.initialData);
  const [page, setPage] = useState("Page1");

  const save = async () => {
    const data = initialData;
    const uid = initialData.uid;

    saveData(uid, data);
    await editUser(data, dispatch, saveData);
    handleClose();
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header className="w-full">
        <Row className="w-full">
          <Row>
            {page === "Page1" && <Modal.Title>Employer Details</Modal.Title>}
            {page === "Page2" && <Modal.Title>Firm Details</Modal.Title>}
          </Row>
          <Row className="flex items-center justify-between">
            <Col className="flex justify-between mt-2">
              <Button
                className="text-xs"
                variant={page === "Page1" ? "primary" : "secondary"}
                onClick={() => setPage("Page1")}
              >
                <p className="font-bold text-black">Employer Details</p>
              </Button>

              {/* Button for Section 2 */}

              <Button
                className="text-xs"
                variant={page === "Page2" ? "primary" : "secondary"}
                onClick={() => setPage("Page2")}
              >
                <p className="font-bold text-black">Firm Details</p>
              </Button>
            </Col>
          </Row>
        </Row>
      </Modal.Header>
      <Modal.Body>
        {page === "Page1" && <Content1 />}
        {page === "Page2" && <Content2 />}
      </Modal.Body>
      <Modal.Footer>
        <Row className="flex items-center justify-between w-full">
          <Col>
            {page === "Page1" && <Modal.Title>Employer Details</Modal.Title>}
            {page === "Page2" && <Modal.Title>Firm Details</Modal.Title>}
          </Col>
          <Col className="flex justify-end">
            {/* Close button */}

            <Button variant="primary" onClick={save}>
              <p className="font-bold text-blue-500">Save</p>
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalRecuriterEditProfile;
