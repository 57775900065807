import { MouseEvent } from "react";
import { Button } from "react-bootstrap";
// @ts-ignore
import { IMAGES } from "../../../constants/images";
import ButtonTooltipWrapper from "../ButtonTooltipWrapper";

interface IProps {
  disabled?: boolean;
  isActive: boolean;
  onClick: (e: MouseEvent) => void;
  activeText?: string;
  inactiveText?: string;
}

const LikeButton = ({
  disabled = false,
  isActive,
  activeText = "Undo like",
  inactiveText = "Like",
  onClick,
}: IProps) => {
  return (
    <ButtonTooltipWrapper disabled={disabled}>
      <Button
        disabled={disabled}
        active={isActive}
        variant="like"
        onClick={(e) => {
          e.stopPropagation();
          onClick(e);
        }}
      >
        <img
          alt={isActive ? activeText : inactiveText}
          src={isActive ? IMAGES.GREEN_HEART_FILLED : IMAGES.GREEN_HEART}
          width={15}
          height={14}
        />
        {isActive ? activeText : inactiveText}
      </Button>
    </ButtonTooltipWrapper>
  );
};

export default LikeButton;
