import { IMessages } from "./messages.types";

const starterMessages: IMessages = {
  candidate: [
    {
      title: "Express interest",
      message:
        "Hello, I'm interested in this position. Are you still accepting applications?",
    },
    {
      title: "Application process",
      message: "Hello, could you outline the next steps in the hiring process?",
    },
    {
      title: "Work-Life Balance",
      message:
        "Hi, could you share more about the firm's approach to work-life balance?",
    },
    {
      title: "Diversity and Inclusion",
      message:
        "Hi, can you share more about the firm's commitment to diversity and inclusion?",
    },
  ],
  firm: [
    {
      title: "Introduction",
      message:
        "Hi #name#, we're impressed with your background and would like to discuss this opportunity with you. Does this position interest you?",
    },
    {
      title: "Interview availability",
      message:
        "Hi #name#, could you let us know your availability for an interview?",
    },
    {
      title: "Motivation",
      message: "Hello #name#, what specifically drew you to apply to our firm?",
    },
    {
      title: "Thank you for liking our position",
      message:
        "Hi #name#, thank you for liking our position! We'd love to learn more about you. Could you share your profile with us?\nAlso, let us know if you have any questions about the role, we'd be happy to answer them.",
    },
  ],
};

export default starterMessages;
